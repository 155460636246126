@import '../../../../../../styles/scss/index.scss';

.userInfo {
  display: flex;
  flex-direction: column;
  .commentsTwo {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .fullName {
      display: flex;
      color: $onyx;
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
}
.comment-list-scroll {
  max-height: 650px; /* Set the max height for the scrollable area */
  overflow-y: auto;  /* Enable vertical scroll */
  padding-right: 10px; /* Optional: space for the scrollbar */
}
.halfDiv {
  display: flex;
  justify-content: space-between;
}

.replyBtn {
  background-color: transparent;
  // position: relative;
  background-repeat: no-repeat;
  // top: 1px;
  border: none;
  color: $spacecadet;
  outline: none;
  font-weight: 600;
  padding: 0 !important;
  margin: 0px 10px 2px 5px !important;
  font-size: 13px;
  border-radius: 4px;

  &:hover {
    outline: none;
    background-color: $cccalendartoday;
  }

  &:focus {
    outline: 0;
  }
}
.replyIcon {
  background-image: url('../../../../../../../public/images/home/back.png');
  width: 24px;
  height: 24px;
  filter: none; // invert(24%) sepia(0%) saturate(0%) hue-rotate(155deg) brightness(98%) contrast(93%);
  margin-top: 0px;
  margin-left: 8px;
  background-repeat: no-repeat;
}

.userActions {
  margin-top: 1px;

  .actionsBtn {
    background-color: transparent;
    border: none;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      outline: none;
      background-color: $cccalendartoday;
      border-radius: 50%;
    }
  }
}
.react-responsive-modal-modal {
  color: $onyx !important;
  font-size: 16px !important;
  font-weight: 400;
  border-radius: 5px;
}
.userLink {
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;

  .imgdefault {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: $spacecadet !important;
  }
}

.replysection {
  display: flex;
  flex-direction: column;
}

.infoStyle {
  margin-left: 3rem;
  font-size: 15px;
  color: $onyx;

  p {
    margin: 0px;
  }
}

.optionIcon {
  background-image: url('../../../../../../../public/images/home/options.svg');
  width: 6px;
  height: 6px;
  filter: invert(24%) sepia(0%) saturate(0%) hue-rotate(155deg) brightness(98%) contrast(93%);
  padding: 7px;
  background-repeat: no-repeat;
}

.emoji-picker-react div.skin-tones-list button#tneutral {
  color: $cccalendartoday;
}

.szh-menu {
  font-family: sans-serif;
  font-size: 0.925rem;
  user-select: none;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  border-radius: 6px;
  padding: 6px !important;
  min-width: 7rem;
  left: -70px !important;
  top: 2px !important;
  color: $onyx;

  .szh-menu__item {
    padding: 5px;
  }

  .szh-menu__item:hover {
    background-image: linear-gradient(10deg,
        $peachorange 10%,
        $cccalendartoday 100%);
  }
}

.react-responsive-modal-modal {
  max-width: 440px !important;

  h2,
  p {
    text-align: center;
  }
}

.deleteBtns {
  display: flex;
  justify-content: center;
}

.delete {
  background-image: linear-gradient(10deg,
      $peachorange 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 25px !important;
  margin: 2px 1px;
  padding: 5px 25px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $salem !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        $peachorange 80%);
    color: $onyx !important;
  }
}

.cancel {
  background-image: linear-gradient(10deg,
      $peachorange 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 25px !important;
  margin: 2px 1px;
  padding: 5px 25px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $salem !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        $peachorange 80%);
    color: $onyx !important;
  }
}

// Additional Design
// .replySection input {
//   color: #b90a0a !important;
//   background-color: #978c8c;
//   border: 1px solid #e10303 !important;
// }

// .replySection button {
//   color: $spacecadet !important;
//   font-size: 14px;
//   font-weight: 400;
// }

.emoji-input {
  border: 1px solid $alto !important;
  background: $white !important;
  border-radius: 20px !important;
  height: 40px !important;
  padding: 2px;
  word-break: break-word !important;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;
  margin-right: 3px !important;
  margin-left: 5px !important;

  &:focus {
    box-shadow: none;
    border-color: $peachorange !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $peachorange !important;
  }

  .emoji-icon {
    background-image: url('../../../../../../../public/images/home/smiles.png');
    position: relative;
    // width: 204px !important;
    background-repeat: no-repeat;
    top: 0;
    padding: 10px;
    margin: 5px !important;
    cursor: pointer;
  }
}

.postComment {
  width: 100%;
  border: none;
  border-bottom: none !important;
  text-decoration: none;
  background-color: transparent;
  margin-left: 6px;
}

.postComment:focus {
  outline: none;
  border-bottom: none;
}

.postComment::placeholder {
  margin-top: -2px;
}

// .postComment{
//   padding: 1px 0px;
//   margin: 1px 0px 0px 0px !important;
//   border: none;
// }
.postBtn {
  background-image: linear-gradient(10deg,
      $peachorange 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 25px !important;
  margin: 5px 1px 4px 3px !important;
  // padding: 0px 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $salem !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        $peachorange 80%);
    color: $onyx !important;
  }
}

.cancelBtn {
  background-image: linear-gradient(10deg,
      $cccalendartoday 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 25px !important;
  margin: 5px 1px 4px 3px !important;
  padding: 1px 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $salem !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        $cccalendartoday 80%);
    color: $onyx !important;
  }
}

// .imgdefault{
//   background-color: $spacecadet !important;
//   width: 38px !important;
//   height: 38px !important;
//   border-radius: 19px !important;
// }
// .userImg {
//   background-color: #978c8c;
// }

.form {
  background-color: $cccalendartoday;
  padding: 10px 0px;
}

button.actionsBtn {
  border: 1px solid $seashell !important;
}

.actionsBtn:active {
  color: $onyx;
  border: 1px solid $peachorange !important;
}

span.comment-title {
  color: $onyx !important;
  font-size: 14px;
}