@import "../../../../styles/scss/index.scss";

// **********   New Layout all
.xcln-job-card-selected{
  color: #4c9d06;
}
.xcln-jobs-dmin-height {
  height: auto;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-height: 150vh;
  background-color: transparent !important;
  // padding-bottom: 1rem;
  /* Custom scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #eeeeee #fffcfc; /* Thumb color, Track color */
}

/* Custom Scrollbar for Chrome, Safari, and Edge */
.xcln-jobs-dmin-height::-webkit-scrollbar {
  width: 4px !important; /* Width of the scrollbar */
}

.xcln-jobs-dmin-height::-webkit-scrollbar-track {
  background: #e1e1e1; /* Track color */
}

.xcln-jobs-dmin-height::-webkit-scrollbar-thumb {
  background-color: #bdbcbc; /* Thumb color */
  border-radius: 10px; /* Roundness of the thumb */
  border: 2px solid #e1e1e1; /* Padding around the thumb */
}

.xcln-jobs-dmin-height::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

.xcln-card-footer {
  background-color: $white;
  border-top: 1px solid $seashell;
  margin: 0;
  padding: 0;
}
.xcln-job-dot{
  font-size: 6px;
  color: $alto;
  margin-bottom: 2px;
  margin-right: 3px;
}
.xcln-job-header-bg{
  background-color: #f9fdff;
}
.xcln-job-icon-size1{
  height: 18px;
  width: 19px;
}
.xcln-job-logo{
  height: 45px !important;
  width: 45px !important;
  border: none;
}
.xcln-jobs-dashed-card {
  border: 1px dashed $alto;
  border-radius: 20px;
  padding: 5px 5px;
}
.xcln-jobs-org-text {
  color: $suvagreydrk !important;
  font-size: 12px;
  font-weight: 600;
}
.xcln-jobs-nor-text {
  color: $onyx !important;
  font-size: 13px;
  font-weight: 500;
}
.xcln-jobs-header-text {
  color: $scorpion !important;
  font-size: 14px;
  font-weight: 600;
}
.card-joblist-lite {
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(171, 182, 182, 0.58);
  border-radius: 15px;
  padding: 2px 4px;
}
.border-right-xcln-jobs {
  // border: none;
  border-right: 1px dashed #550707 !important;
  padding-left: 5px;
}
.bg-jobs-public{
  background-color: #fefeff;
}
.search-container {
  display: flex !important;
  text-align: center !important;
  align-items: center !important;
  gap: 0;
  flex-wrap: wrap;
  border: none;
  border-radius: 100px;
  background-color: #ffffff;
  padding: 10px 20px;
  min-height: 110px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  margin: 5px 10px 20px 10px;
}

.button-search-jobs {
  background: transparent;
  border: none;
  color: #2c3e50 !important;
  cursor: pointer;
  font-size: 25px;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    background-color: $cccalendartoday;
    color: #800101 !important;
  }
}
.margin-search-jobs{
  margin-top: 70px !important;
}
.align-job-flex {
  text-align: center;
}

.xcln-job-schedule-container {
  display: flex;
  align-items: center;
}

.xcln-chip-box-jobs-pvt {
  margin-right: 8px;
  position: relative;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color: $dimgray;
}

.xcln-pipe-separator {
  margin: 0 8px;
  color: #575656;
}
.xcln-job-button-nxt {
  background-color: $white;
  border: 1px solid #f0f6fc;
  border-radius: 50%;
  color: #2c3e50;
  padding: 1px 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
  cursor: pointer;
  box-shadow: 2px 5px 6px rgba(7, 7, 7, 0.08);
  &:hover {
    background-color: $white;
    border: 1px solid #f0f6fc;
    color: #6f0219;
  }
  &:focus {
    box-shadow: none;
    background-color: $white;
    border: 1px solid #f0f6fc;
    color: #6f0219;
  }
  &:disabled {
    box-shadow: none;
    background-color: #a3a6a8;
    border: 1px solid #a3a6a8;
    color: $white;
  }
}
.des-details-jobs-bg{
  background-color: #fff7ff;
  padding: 5px 5px;
  border-radius: 10px;
}
.margin-jobs-xcln-card{
  margin: 1px 6px 1px 0 !important;
  padding-bottom: 10px;
}
.margin-left-search-jobs-xcln{
  margin-left: 5px;
}
/* Responsive Styles */
@media (max-width: 468px) {
  .margin-left-search-jobs-xcln{
    margin: 0;
    padding: 1px 3px;
  }
  .margin-jobs-xcln-card{
    margin: 0 !important;
  }
  .search-container {
      flex-direction: column;
      border: none;
      border-radius: 10px;
      padding: 10px 4px;
      margin: 1px 2px;
      height: auto;
      box-shadow: none;
  }
  .margin-search-jobs{
    margin-top: 100px !important;
  }
  .margin-select-jobs{
    margin-bottom: 15px !important;
  }
  .align-job-flex {
    display: flex;
    text-align: start !important;
    align-items: flex-start;
  }
  .align-job-flex-ceter {
    display: flex !important;
    text-align: center !important;
    align-items: center !important;
  }
}
