@import "../../../styles/scss/index.scss";

.campusXcel-jobs-bg {
  content: "";
  // margin-top: 40px;
  // background-image: linear-gradient(
  //   105deg,
  //   rgb(1, 33, 80) 0%,
  //   #15447b,
  //   #018db4,
  //   rgb(1, 82, 85) 85%
  // );
  // background-repeat: no-repeat;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // position: fixed !important;
  // width: 100%;
  // z-index: 1030;
}

.jobs-type-text {
  color: $scorpion !important;
  font-size: 14px;
  font-weight: 400;
}
.dropdown-filter-jobs {
  // position:static;
  // z-index: 9999;
  // min-width: 450px;
  width: 450px !important;
}

.dropdown-toggle-filter-jobs {
  background-color: $white;
  border: 1px solid $onyx;
  border-radius: 5px;
  color: $onyx;
  // width: 90%;
  // min-width: 150px !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  padding: 8px 15px;
  font-weight: 600;
  &:focus {
    border: 1px solid $suvagrey !important;
    background-color: $white;
    color: $onyx;
  }

  &:hover {
    border: 1px solid $peachorange !important;
    background-color: $white;
    color: $onyx;
  }
}
.card-filter-jobs {
  padding: 15px;
  border: none;
  background-color: transparent;
  box-shadow: none;

  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input[type="checkbox"] {
      margin-right: 10px;
    }
  }
}
.pagination-buttons-filter-jobs {
  margin: 5px 0;
}
.btn-filter-jobs {
  padding: 1px 2px;
  border-radius: 50%;
  background-color: #0d4b6a;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0480ac;
  }

  &:disabled {
    background-color: #b5b4b4;
    cursor: not-allowed;
  }
}
.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.input-wrapper input[type="text"] {
  border: 1px solid #ccbfbf !important;
  background: $white !important;
  border-radius: 10px !important;
  height: 45px !important;
  color: $onyx;
  font-size: 16px;
  font-weight: 400;
  &::placeholder{
    color: #a2a0a0 !important;
    opacity: 1;
    font-size: 13;
  }
  &:focus {
    box-shadow: none;
    border-color: $suvagrey !important;
    color: $onyx;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $suvagrey !important;
  }
}

.input-wrapper button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  background-color: transparent;
  color: #034161;
  padding: 0 13px;
  cursor: pointer;
  border-radius: 50%;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    background-color: transparent;
    color: #0d4b6a !important;
  }
}

.card-carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 1px 0;
  background-color: transparent;
}
.card-logo-data {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
  background-color: transparent;
}

.card-carousel {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  scroll-snap-type: x mandatory;
}
.card-item {
  flex: 0 0 auto;
  scroll-snap-align: start;
  margin-right: 1rem;
  /* Ensure cards do not shrink or grow */
}

.job-card-shadow {
  border: 1px solid #969898;
  // box-shadow: 0 2px 3px rgba(107, 107, 129, 0.38);
  border-radius: 16px;
}

.pub-job-card-details {
  border: 1px solid #d9dcdc;
  border-radius: 16px;
  padding: 3px;
}

.card-logo-data {
  border: none;
  box-shadow: 0px 5px 7px rgba(20, 20, 21, 0.38);
  border-radius: 50px;
  padding: 5px;
  margin: 5px;
}
.margin-label-job{
  margin-left: 2px;
}
.jobs-logo-round {
  border: none;
  box-shadow: 0px 10px 17px rgba(20, 20, 21, 0.38);
  border-radius: 50%;
  padding: 5px;
  margin: 1px;
}
.jobs-org-logo {
  height: 100px;
  width: 200px;
}
.scroll-button {
  background: #004b71;
  color: $white;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 50%;
  &:hover {
    background-color: #0480ac;
  }
  &:disabled {
    background-color: #5e6161;
    opacity: .43;
  }
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}
.logo-new-job{
  height: 30px;
  width: 30px;
}
.bg-jobs-list {
  padding: 1px 2px;
  border-radius: 50%;
  background-color: #0d4b6a;
}
.card-jobs-lite {
  border: none;
  border-radius: 5px;
  padding: 0;
  // box-shadow: 0px 5px 7px rgba(99, 100, 100, 0.38);
}

.input-card-header-text{
  font-weight: 600;
  color: $dimgray;
}
.input-card-header-sub-text{
  font-weight: 500;
  color: $scorpion;
  font-size: 13px;
}
.form-collapse-card-header{
  font-weight: 500;
  color: $onyx;
  font-size: 14px;
}
.mob-hide-jobs-tp{
  display: block;
}
.jobs-apply-text {
  font-size: 12px;
  font-weight: 700;
  color: $darkred;
  font-style: italic;
  &:hover {
    background-color: transparent;
    color: $spacecadet !important;
  }
}

// **********   New Layout all
.pub-job-card-selected{
  color: #690303;
  font-weight: 600;
}
.pub-jobs-dmin-height {
  height: auto;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-height: 150vh;
  background-color: transparent !important;
  // padding-bottom: 1rem;
  /* Custom scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #eeeeee #fffcfc; /* Thumb color, Track color */
}

/* Custom Scrollbar for Chrome, Safari, and Edge */
.pub-jobs-dmin-height::-webkit-scrollbar {
  width: 4px !important; /* Width of the scrollbar */
}

.pub-jobs-dmin-height::-webkit-scrollbar-track {
  background: #e1e1e1; /* Track color */
}

.pub-jobs-dmin-height::-webkit-scrollbar-thumb {
  background-color: #bdbcbc; /* Thumb color */
  border-radius: 10px; /* Roundness of the thumb */
  border: 2px solid #e1e1e1; /* Padding around the thumb */
}

.pub-jobs-dmin-height::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

.pub-card-footer {
  background-color: $white;
  border-top: 1px solid $seashell;
  margin: 0;
  padding: 0;
}
.pub-job-dot{
  font-size: 6px;
  color: $alto;
  margin-bottom: 2px;
  margin-right: 3px;
}
.pub-job-header-bg{
  background-color: #f9fdff;
}
.pub-job-icon-size1{
  height: 18px;
  width: 16px;
}
.pub-job-logo{
  height: 45px !important;
  width: 45px !important;
  border: none;
}
.pub-jobs-dashed-card {
  border: 1px dashed $alto;
  border-radius: 20px;
  padding: 5px 5px;
}
.pub-jobs-org-text {
  color: $suvagreydrk !important;
  font-size: 12px;
  font-weight: 600;
}
.pub-jobs-nor-text {
  color: $onyx !important;
  font-size: 13px;
  font-weight: 500;
}
.pub-jobs-header-text {
  color: #142196 !important;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}
.card-joblist-lite-pub {
  // box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(183, 204, 204, 0.58);
  border-radius: 15px;
  padding: 2px 4px;
  margin-bottom: 5px;
}
.border-right-pub-jobs {
  // border: none;
  border-right: 1px dashed #550707 !important;
  padding-left: 5px;
}
.bg-jobs-public{
  background-color: #fefeff;
}
.search-container {
  display: flex !important;
  text-align: center !important;
  align-items: center !important;
  gap: 0;
  flex-wrap: wrap;
  border: none;
  border-radius: 100px;
  background-color: #ffffff;
  padding: 10px 20px;
  min-height: 110px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  margin: 5px 10px 20px 10px;
}

.button-search-jobs {
  background: transparent;
  border: none;
  color: #2c3e50 !important;
  cursor: pointer;
  font-size: 25px;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    background-color: $cccalendartoday;
    color: #800101 !important;
  }
}
.margin-search-jobs{
  margin-top: 70px !important;
}
.align-job-flex {
  text-align: center;
}

.pub-job-schedule-container {
  display: flex;
  align-items: center;
}

.pub-chip-box-jobs-pvt {
  margin-right: 8px;
  position: relative;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color: $dimgray;
}

.pub-pipe-separator {
  margin: 0 8px;
  color: #575656;
}
.pub-job-button-nxt {
  background-color: $white;
  border: 1px solid #f0f6fc;
  border-radius: 50%;
  color: #2c3e50;
  padding: 1px 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
  cursor: pointer;
  box-shadow: 2px 5px 6px rgba(7, 7, 7, 0.08);
  &:hover {
    background-color: $white;
    border: 1px solid #f0f6fc;
    color: #6f0219;
  }
  &:focus {
    box-shadow: none;
    background-color: $white;
    border: 1px solid #f0f6fc;
    color: #6f0219;
  }
  &:disabled {
    box-shadow: none;
    background-color: #a3a6a8;
    border: 1px solid #a3a6a8;
    color: $white;
  }
}
.des-details-jobs-bg{
  background-color: #fff7ff;
  padding: 5px 5px;
  border-radius: 10px;
}
.margin-jobs-pub-card{
  margin: 1px 6px 1px 0 !important;
}
.margin-left-search-jobs-pub{
  margin-left: 5px;
}
/* Responsive Styles */
@media (max-width: 468px) {
  .margin-left-search-jobs-pub{
    margin: 0;
    padding: 1px 3px;
  }
  .margin-jobs-pub-card{
    margin: 0 !important;
  }
  .search-container {
      flex-direction: column;
      border: none;
      border-radius: 10px;
      padding: 10px 4px;
      margin: 1px 2px;
      height: auto;
      box-shadow: none;
  }
  .margin-search-jobs{
    margin-top: 100px !important;
  }
  .margin-select-jobs{
    margin-bottom: 15px !important;
  }
  .align-job-flex {
    display: flex;
    text-align: start !important;
    align-items: flex-start;
  }
  .align-job-flex-ceter {
    display: flex !important;
    text-align: center !important;
    align-items: center !important;
  }
}
