@import "../../styles/scss/index.scss";

.custom-popover-wrapper {
  position: relative;
  display: inline-block;
  background-color: transparent;
}

.custom-popover {
  position: absolute;
  background-color: #fff;
  color: #333;
  text-align: left;
  border-radius: 4px;
  padding: 5px 10px;
  z-index: 1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word;
  width: auto; /* Let the width adjust based on content */
  min-width: 280px;
  max-height: 300px;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
  &.custom-popover-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 10px;
      border-style: solid;
      border-color: #fff transparent transparent transparent; /* Custom arrow color for top */
    }
  }

  &.custom-popover-right {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 10px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-width: 10px;
      border-style: solid;
      background-color: transparent;
      border-color: transparent transparent transparent #fff; /* Custom arrow color for right */
    }
  }

  &.custom-popover-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-75%);
    margin-top: 10px;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 80%;
      transform: translateX(-90%);
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent; /* Custom arrow color for bottom */
    }
  }

  &.custom-popover-left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 10px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-width: 10px;
      border-style: solid;
      border-color: transparent #fff transparent transparent; /* Custom arrow color for left */
    }
  }
}

.custom-popover-header {
  font-weight: 500;
  margin-bottom: 2px;
  background-color: transparent;
}

.custom-popover-content {
  font-size: 14px;
  white-space: normal; /* Allow text wrapping */
}

// .align-center {
//   text-align: center;
// }

// .button-icon {
//   background: none;
//   border: none;
//   cursor: pointer;
// }

.custom-custom-popover {
  max-width: none; /* Remove maximum width if not needed */
}
.custom-popover::-webkit-scrollbar {
  width: 8px;
}

.custom-popover::-webkit-scrollbar-thumb {
  background-color: #faedd7;
  border-radius: 8px;
}

.custom-popover::-webkit-scrollbar-thumb:hover {
  background-color: #c6c7d2 !important;
}