$white: #ffffff;
$onyx: #383838;
$dimgray:#616161;
$scorpion: #575656;
$suvagrey: #959191;
$suvagreydrk: #868484;
$oslogray: #8c8f95;
$darkgray: #aaa8a8;
$alto: #e0e0e0;
$darkred: #7d0e12;
$litered: #fac9c9;
$cccalendartoday: #fbfbf9;
$peachorange:#F9CB9C;
$seashell: #FFF8F2;
$spacecadet: #1c1336;
$amethyst: #f8f5fb;
$greenvogue: #11404D;
$salem: #0A8242;
$eucalyptus: #3B9B68;
$cerulean: #10a7c1;
$britvil: #290141;