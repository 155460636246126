@import "../../styles/scss/index.scss";

.campusXcel-bg-component {
  margin: 0 !important;
  padding: 0 !important;
}

$displacement: 3px;

@keyframes shake-shake {
  0% {
    transform: translateX(-$displacement);
  }

  20% {
    transform: translateX($displacement);
  }

  40% {
    transform: translateX(-$displacement);
  }

  60% {
    transform: translateX($displacement);
  }

  80% {
    transform: translateX(-$displacement);
  }

  100% {
    transform: translateX(0px);
  }
}
.key-highlights-cards {
  border: none;
  box-shadow: 0px 10px 17px rgba(20, 20, 21, 0.38);
  border-radius: 8px;
  background-color: rgba(255, 255, 251, 0.8);
}
.cx-register-session {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin: auto auto;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
}
.bg-for-icon {
  background-color: #ffffff;
}
// .campus-register-left {
//     width: 75%;
//     height: auto;
//     min-height: 100vh;
//     position: relative;
//     background-image: url("../../../public/images/campus/gamified.png");
//     background-size: cover;
// }

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.footer {
  // background-color: #333;
  // color: white;
  // padding: 1rem;
  text-align: center;
  margin-top: auto;
}
@media screen and (max-width: 480px) {
  .cx-register-left {
    display: none;
  }
}

// cx key highlights
.campus-key-bg {
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  background-color: $white;
  padding: 5px;
  width: 75%;
}
