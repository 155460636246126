@import "../../../../../styles/scss/index.scss";

#eTemplate-card {
  border: none !important;
  padding: 0;
}
.eTemplate-img-icon {
  height: 12px;
  width: 12px;
  margin-bottom: 2px;
}
.eTemplate-card-design {
  border: 1px solid #f77f7f;
  border-radius: 7px;
  // padding: 10px 5px;
}
.eTemplate-img-icon-main {
  height: 15px;
  width: 15px;
  margin-bottom: 5px;
}
.eTemplate-card-design {
  border: none !important;
}
.eTemplate-header{
  font-size: 16px;
  font-weight: 700;
  color: rgb(3, 148, 148);
}
.eTemplate-exp-icon{
  color: rgb(3, 148, 148);
}
.eTemplate-subheader{
  font-size: 15px;
  font-weight: 600;
  color: rgb(13, 129, 129);
}
.eTemplate-subheader-italic{
  font-size: 15px;
  font-weight: 500;
  color: rgb(97, 105, 105);
  font-style: italic;
}
.eTemplate-hr {
  border: 1px solid rgb(184, 206, 206);
  padding: 0;
  margin: 0;
}
.eTemplate-text {
  font-size: 15px;
  font-weight: 400;
  color: #4c4343;
}