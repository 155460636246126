@import "../../styles/scss/index.scss";
// .sun-editor{
//     border: 1px solid $crusta !important;
//     border-radius: 1px !important;
// }
.se-modal-title {
  color: $scorpion;
  font-size: 16px;
  font-weight: 500;
}

.se-btn.se-dialog-close {
  color: $scorpion !important;
  border: 1px solid $alto !important;
}

.se-dialog-tabs {
  outline: 0 !important;
  border-top: none !important;
  border-bottom: 1px solid $cccalendartoday !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
  color: $dimgray !important;
  margin-left: 5px;
  margin-top: 2px;
  font-weight: 500;
  padding: 1px 1px;
}

.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button {
  border: 1px solid $alto;
  color: $scorpion;
}

.sun-editor svg {
  fill: $scorpion !important;
}

.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-anchor-preview-form
  .se-svg.se-anchor-preview-icon {
  color: $scorpion !important;
  margin-left: 10px !important;
}

// input.se-dialog-btn-check {
//     appearance: none;
//     -webkit-appearance: none;
//     width: 16px;
//     height: 16px;
//     border: 1px solid $scorpion !important;
//     background-color: $white;
//     outline: none !important;
//     cursor: pointer;
//     &:focus {
//         box-shadow: none;
//         border-color: $litered !important;
//         color: $scorpion;
//         background-color: $white !important;
//     }
//     &:hover {
//         border: 1px solid $litered !important;
//     }
//   }

//   input.se-dialog-btn-check:checked {
//     background-color: $scorpion !important;
//     color: $white !important;
//     margin: 5px !important;
//     outline: none !important;
//     &:focus {
//         box-shadow: none;
//     }
//     &:hover {
//         border: 1px solid $litered !important;
//     }
//   }
input.se-dialog-btn-check {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  margin: 5px !important;
  width: 17px;
  height: 17px;
  border: 1px solid $litered !important;
  border-radius: 3px !important;
  background-color: $white;
  outline: none !important;
  cursor: pointer;

  &:focus {
    box-shadow: none;
    border-color: $litered !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $litered !important;
  }

  &::before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: transparent;
    font-size: 13px;
    font-weight: 900;
    width: 17px;
    height: 17px;
    display: flex;
    padding-left: 2px;
    align-items: center !important;
    border: 1px solid $litered;
    border-radius: 3px !important;
    background-color: $white;
    cursor: pointer;
  }
}

input.se-dialog-btn-check:checked {
  background-color: $litered !important;
  color: $white !important;
  margin: 5px !important;
  outline: none !important;

  &:focus {
    box-shadow: none;
  }

  &::before {
    color: $white;
    background-color: $litered;
  }

  &:hover {
    border: 1px solid $litered !important;
  }
}

input.se-dialog-btn-radio {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  margin: 5px !important;
  width: 17px;
  height: 17px;
  border: 1px solid $litered !important;
  border-radius: 50% !important;
  background-color: $white;
  outline: none !important;
  cursor: pointer;

  &:focus {
    box-shadow: none;
    border-color: $litered !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $litered !important;
  }

  &::before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: transparent;
    font-size: 13px;
    font-weight: 900;
    width: 17px;
    height: 17px;
    display: flex;
    padding-left: 2px;
    align-items: center !important;
    border: 1px solid $litered;
    border-radius: 50% !important;
    background-color: $white;
    cursor: pointer;
  }
}

input.se-dialog-btn-radio:checked {
  background-color: $litered !important;
  color: $white !important;
  margin: 5px !important;
  outline: none !important;

  &:focus {
    box-shadow: none;
  }

  &::before {
    color: $white;
    background-color: $litered;
  }

  &:hover {
    border: 1px solid $litered !important;
  }
}

// .sun-editor .se-dialog .se-dialog-inner .se-dialog-footer>div>label {
//     margin: 1px 3px !important;
//     padding: 1px 3px !important;
// }

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
  border: 1px solid $litered !important;
  background: $white !important;
  border-radius: 5px !important;
  height: 40px !important;
  padding: 2px 3px !important;
  margin: 2px 3px !important;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $litered !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $litered !important;
  }
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-control {
  border: 1px solid $alto !important;
  background: $white !important;
  border-radius: 3px !important;
  height: 30px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
  &:focus {
    box-shadow: none;
    border-color: $litered !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $litered !important;
  }
}

.se-input-form.se-input-url {
  border: 1px solid $alto !important;
  background: $white !important;
  border-radius: 30px !important;
  height: 40px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $litered !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $litered !important;
  }
}

.se-btn-tray {
  border: none !important;
  border-radius: 1px !important;
  background-color: $cccalendartoday !important;
}

.sun-editor .se-btn {
  padding: 3px;
  border: 1px solid $scorpion;
  margin: 1px 3px !important;
}

button.se-btn.se-tooltip {
  color: #301e6e !important;
  padding: 2px !important;
  margin: 2px 4px !important;

  &:hover {
    outline: none !important;
    color: $scorpion !important;
    border: 1px solid #301e6e;
    background-color: $cccalendartoday !important;
  }

  &:focus {
    outline: 0 !important;
    color: $scorpion !important;
    border: 1px solid #301e6e;
  }
}

.sun-editor .se-resizing-bar {
  border: none !important;
  background-color: $white !important;
}

.sun-editor .se-resizing-bar .se-navigation {
  outline: none !important;
  border: none !important;
  background-color: $white !important;
  color: $white !important;
}

.sun-editor .se-btn-select.se-btn-tool-format {
  padding: 3px !important;
}

.sun-editor .se-btn-select.se-btn-tool-size {
  padding: 3px !important;
}

.sun-editor .se-btn-select.se-btn-tool-font {
  padding: 3px !important;
}

.se-btn-primary {
  //   background-image: linear-gradient(10deg,
  //       $litered 10%,
  //       $cccalendartoday 100%);
  background-color: $seashell !important;
  border: 1px solid $suvagrey !important;
  border-radius: 1px;
  margin: 1px 3px 2px 5px;
  padding: 5px 1px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $onyx !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    // background-image: linear-gradient(100deg,
    //     $cccalendartoday 10%,
    //     $litered 80%);
    border: 1px solid $litered !important;
    color: $onyx !important;
  }
}

.sun-editor .se-dialog-tabs button {
  background-color: $white;
  border-right: 1px solid $cccalendartoday;
  float: left;
  outline: none;
  padding: 2px 13px;
  transition: 0.3s;
}

.sun-editor .se-dialog-tabs button.active {
  background-color: $litered !important;
  border-right: 1px solid $cccalendartoday;
  float: left;
  outline: none;
  padding: 2px 13px;
  transition: 0.3s;
}
// New
.se-color-input {
  border: 1px solid #301e6e !important;
  padding-left: 2px !important;
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  display: block; /* Set to block so text wraps properly */
  text-align: left; /* Aligns text to the left */
}
.sun-editor-editable {
  /* This isfor default text size in editor */
  font-size: 18px;
}
