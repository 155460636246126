@import "../../styles/scss/index.scss";

.table-container {
  // display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
}
table tr:nth-child(odd) td {
  background: #f5f8fa;
}
table tr:nth-child(even) td {
  background: #fff;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 4px 0;
  font-size: 14px;
  text-align: left;
  thead {
    th {
      padding: 15px;
      font-weight: 600;
      text-transform: uppercase;
      color: #ecf0f1;
      background-color: #2c3e50 !important;
      border-bottom: 2px solid #dbe1e3;
      &:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
  tbody {
    tr {
      // &:nth-child(odd) {
      //   background-color: #e2eef9 !important;
      // }
      // &:nth-child(even) {
      //   background-color: #e9ecef;
      // }
      // &:hover {
      //   background-color: #ced4da;
      // }
      td {
        padding: 15px;
        border-bottom: 1px solid #dee2e6;
        &:first-child {
          border-left: 1px solid #dee2e6;
        }

        &:last-child {
          border-right: 1px solid #dee2e6;
        }
        // Ensure text doesn't overflow and adjusts according to width
        // overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        // max-width: 1px;
      }
    }
  }
}
.table-margin-bot {
  padding-bottom: 4px;
}
.table-text-no {
  font-size: 14px;
  font-weight: 700;
  color: $onyx;
}
.table-text {
  font-size: 13px;
  font-weight: 700;
  color: $suvagrey;
}
// .table-icon-danger{
//   font-size: 13px;
//   font-weight: 700;
//   //
//   &:active{
//     color: #da4403 !important;
//   }
//   &:disabled{
//     color: rgb(55, 59, 62, .1) !important;
//   }
// }
.button-icon-danger[disabled] {
  background: transparent;
  color: $dimgray !important;
}

.button-icon-danger {
  background: transparent;
  border: none;
  color: #da4403 !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    background-color: $cccalendartoday;
    color: #870b00 !important;
  }
}

.button-icon-success {
  background: transparent;
  border: none;
  color: #0ab020 !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    background-color: $cccalendartoday;
    color: #32d345 !important;
  }
}

.button-icon-edit {
  background: transparent;
  border: none;
  color: #2c3e50 !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    background-color: $cccalendartoday;
    color: #0c1825 !important;
  }
}

.table-button {
  background-color: #2c3e50;
  border: 1px solid #2c3e50;
  border-radius: 2px;
  color: $white;
  padding: 1px 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
  cursor: pointer;
  box-shadow: 2px 5px 6px rgba(7, 7, 7, 0.08);
  &:hover {
    border-color: $onyx !important;
    color: $white;
    background-color: $onyx !important;
  }
  &:focus {
    box-shadow: none;
    border-color: $onyx !important;
    color: $white;
    background-color: $onyx !important;
  }
}
.table-norecords {
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 60%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cx-info-header{
  font-weight: 600;
  color: $onyx;
  font-size: 15px;
}
.cx-info-text{
  font-weight: 500;
  color: $onyx;
  font-size: 14px;
}