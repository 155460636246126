$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

h1 {
  font-size: 75px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}

h2 {
  font-size: 55px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}

h3 {
  font-size: 40px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}

h4 {
  font-size: 30px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}

h5 {
  font-size: 20px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}

h6 {
  font-size: 18px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 16px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}

// span {
//   font-size: 15px;
//   color: $dimgray;
//   font-family: "Poppins", sans-serif;
// }
// bg-cx image
.cx-image-bg {
  content: "";
  background-image: url("../../../public/cx-trans.png");
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // position: absolute;
}
// Input & Forms
.form-header-text {
  color: $dimgray;
  font-size: 20px;
  font-weight: 500;
}
.form-check-text {
  color: $dimgray;
  font-size: 14px;
  font-weight: 500;
}
.text-whatsapp {
  font-size: 12px !important;
  color: $salem;
}
.text-lite-grey {
  color: $oslogray;
  font-size: 13px;
  font-weight: 400;
}
.text-lite-dark {
  color: $onyx;
  font-size: 13px;
  font-weight: 400;
}
.text-lite-grey-sub {
  color: $dimgray;
  font-style: italic;
  font-size: 15px;
  font-weight: 500;
  &:hover {
    color: $darkred !important;
  }
}
.line-height-cx {
  line-height: 1.2;
}
.mandate-star {
  color: $darkred;
}
.campus-input::placeholder {
  color: #aa9d9d;
  opacity: 1;
}
.campus-input {
  border: 1px solid $alto !important;
  background: $white !important;
  border-radius: 10px !important;
  height: 50px !important;
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $darkred !important;
    color: $scorpion;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $darkred !important;
  }
  &:disabled {
    border: 1px solid $alto !important;
    background: $cccalendartoday !important;
  }
}
.campus-input-search {
  border: 1px solid $alto !important;
  background: $white !important;
  border-radius: 50px !important;
  height: 40px !important;
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  &::placeholder {
    color: $darkgray;
  }
  &:focus {
    box-shadow: none;
    border-color: $darkred !important;
    color: $scorpion;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $darkred !important;
  }
}
.campus-input-dob {
  border: 1px solid $alto !important;
  background: $white !important;
  border-radius: 5px !important;
  height: 35px !important;
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $darkred !important;
    color: $scorpion;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $darkred !important;
  }
}
.inline-dropdown {
  display: inline-block;
  margin: 0 4px;
}
.campus-text-area {
  min-height: 80px !important;
  border: 1px solid $alto !important;
  background-color: $white !important;
  border-radius: 2px !important;
  padding: 1;
  color: $onyx;
  font-size: 15px;
  font-weight: 400;
  &::placeholder {
    color: #aa9d9d;
    opacity: 1;
  }
  &:focus {
    box-shadow: none;
    border-color: $darkred !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $darkred !important;
  }
  &:disabled {
    border: 1px solid $cccalendartoday !important;
    background: $cccalendartoday !important;
  }
}

.campus-text-area-desc {
  min-height: 180px !important;
  border: 1px solid $alto !important;
  background-color: $white !important;
  border-radius: 5px !important;
  padding: 1;
  color: $onyx;
  font-size: 15px;
  font-weight: 400;
  &::placeholder {
    color: #aa9d9d;
    opacity: 1;
  }
  &:focus {
    box-shadow: none;
    border-color: $darkred !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $darkred !important;
  }
}

// Buttons
.campus-button-all {
  background-color: $onyx !important;
  border: 1px solid $onyx !important;
  border-radius: 5px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $white !important;
  padding: 5px 15px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    border: 1px solid $darkred !important;
    background-color: $darkred !important;
  }

  &:hover {
    background-color: $darkred !important;
    border: 1px solid $darkred !important;
    color: $white !important;
  }
}
.campus-button {
  background-color: $darkred !important;
  border: 1px solid $darkred !important;
  border-radius: 10px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $white !important;
  padding: 10px 0 !important;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: $darkred !important;
  }

  &:hover {
    background-color: $darkred !important;
    color: $white !important;
  }
}

.campus-button-lite {
  background-color: $white !important;
  border: 1px solid $darkred !important;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $onyx !important;
  padding: 6px 8px 7px 12px !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: $darkred !important;
  }

  &:hover {
    background-color: $darkred !important;
    color: $white !important;
  }
}

.button-icon-know-more {
  background: transparent;
  border: none;
  color: $darkred !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $onyx !important;
  }
}

.intro-btn-filled {
  background-image: linear-gradient(10deg, $darkred 100%, $cccalendartoday 10%);
  border: none !important;
  border-radius: 10px;
  // margin: 1px 3px 2px 45px;
  padding: 15px 55px;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    background: $darkred;
    color: $white !important;
  }
}

.button-icon[disabled] {
  background: transparent;
  color: $dimgray !important;
}

.button-icon {
  background: transparent;
  border: none;
  color: $scorpion !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }

  &:hover {
    background-color: $cccalendartoday;
    color: $darkred !important;
  }
}

.button-icon-active {
  color: $darkred !important;
}

.button-icon-inactive {
  color: $dimgray !important;
}

.round-small-button {
  background-color: transparent;
  border: 1px solid $darkred;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 2px;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    border: 1px solid $darkred !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    border: 1px solid $darkred;
    background-color: $cccalendartoday;
  }
}
.round-small-icon {
  color: $onyx;
}

.button-transparent {
  background: transparent;
  border: none;
  color: $darkred !important;
  font-size: 13px;
  font-style: italic;
  padding: 0;
  margin-bottom: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $suvagrey !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.button-border {
  background: white;
  border: 1px solid $white;
  color: $onyx !important;
  font-size: 17px;
  font-weight: 700;
  font-style: italic;
  padding: 5px 20px;
  margin-bottom: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.form-card-white {
  background: $white;
  border: none;
}
.cursor-pointer {
  cursor: pointer;
}
// Check and Switchbox

.campus-switch-box {
  color: $darkred;
  background-color: $white;
  // height: 50px;
  width: auto;
  // max-width: 130px;
  padding: 3px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none !important;
  box-shadow: 0px 1px 1px $darkred;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background: none !important;
  }
  &:focus:not(:hover) {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg, $alto 10%, $peachorange 80%);
    color: $onyx !important;
  }
}
.campus-switch-box:focus:not(:hover) {
  background-color: $darkred;
}

.campus-check-box {
  color: $darkred;
  margin-top: 5px;
  outline: 1px solid $darkred !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $darkred;
  margin-right: 10px;
  width: 13px;
  height: 13px;
  border-radius: 1px !important;
}

.campus-check-box:checked {
  background-color: $darkred !important;
  border: 1px solid $darkred !important;
  box-shadow: 0 0 1px $darkred;
}

.campus-check-box:focus,
.label::after,
.label.campus-check-box:focus,
.campus-check-box:checked::after,
.campus-check-box:not(.disabled):not(.disabled):active:focus {
  color: $darkred;
  outline: 1px solid $darkred !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $darkred;
  // box-shadow: 0 0 0 .1rem  $white !important;
}

.campus-radio-box {
  color: $darkred;
  // margin-top: 5px;
  outline: 1px solid $darkred !important;
  border: 1px solid $white !important;
  box-shadow: none; //0 0 1px $darkred;
  // margin-right: 10px;
  // width: 1px;
  // height: 13px;
  // border-radius: 50% !important;
}

.campus-radio-box:checked {
  background-color: $darkred !important;
  border: 1px solid $darkred !important;
  box-shadow: none;
  // box-shadow: 0 0 1px $darkred;
}

.campus-radio-box:focus,
.label::after,
.label.campus-radio-box:focus,
.campus-radio-box:checked::after,
.campus-radio-box:not(.disabled):not(.disabled):active:focus {
  color: $white;
  border: 1px solid $darkred !important;
  outline: 1px solid $white !important;
  box-shadow: none;
  // border: 1px solid $white !important;
  // box-shadow: 0 0 0 .1rem  $white !important;
}

.campus-diffable-untick-text {
  font-size: 14px;
  font-weight: 600;
  color: $onyx;
}
.form-card-header {
  background-color: $cccalendartoday;
  border-bottom: 1px solid $seashell;
  margin: 0;
  padding: 0;
}
.form-card-footer {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.nav-tabs {
  border-bottom: none !important;
}
.modal-right-to-left {
  position: fixed;
  z-index: 9999;
  opacity: 1;
  animation-name: modal-right-to-left;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  transform-origin: top right;
  height: 100%;
  // min-height: 110vh;
  top: -30px;
  right: 0;
  left: 32%;
  border-radius: 1px !important;
  transform: translateX(100%);
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
}
// modal screen
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: 1px;
  outline: 0;
  // min-height: 110vh;
}

@keyframes modal-right-to-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.margin-mob-lr {
  margin: 0 5px;
}

.margin-mob-lr1 {
  margin: 0 10px;
}

.margin-mob-lr2 {
  margin: 0 15px;
}

.margin-mob-lr3 {
  margin: 0 25px;
}

.padding-mob-lr {
  padding: 0 5px;
}

.padding-mob-lr1 {
  padding: 0 10px;
}

.padding-mob-lr2 {
  padding: 0 15px;
}

.padding-mob-lr3 {
  padding: 0 25px;
}

@media screen and (max-width: 480px) {
  .margin-mob-lr {
    margin: 0;
  }
  .margin-bottom {
    margin-bottom: 20px;
  }
  .margin-top {
    margin-top: 40px;
  }
  .padding-top {
    padding-top: 40px;
  }
  .margin-mob-lr1 {
    margin: 0;
  }

  .margin-mob-lr2 {
    margin: 0;
  }

  .padding-mob-lr {
    padding: 0;
  }

  .padding-mob-lr1 {
    padding: 0;
  }

  .padding-mob-lr2 {
    padding: 0;
  }

  .margin-mob-lr3 {
    margin: 0;
  }

  .padding-mob-lr3 {
    padding: 0;
  }
  .modal-right-to-left {
    position: fixed;
    z-index: 9999;
    opacity: 1;
    animation-name: modal-right-to-left;
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    transform-origin: top right;
    height: 100%;
    top: 0;
    right: 0;
    left: 0 !important;
    width: 100%;
    transform: translateX(0);
  }
}
.campus-file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 90px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}

.campus-file-upload-box .campus-file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.campus-file-upload-input[disabled] {
  cursor: not-allowed;
}

.campus-file-link {
  color: $dimgray;
  text-decoration: none;
  font-style: initial;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    color: $darkred !important;
  }
}
.form-control:focus {
  box-shadow: none;
}
input[type="file"]:focus {
  background-color: transparent;
}
.campus-file-upload-box .file-link:hover {
  text-decoration: none;
}
input[type="file"]::file-selector-button {
  background-image: linear-gradient(10deg, $dimgray 10%, $onyx 100%);
  border: none !important;
  border-radius: 2px;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(
      100deg,
      $cccalendartoday 10%,
      $peachorange 80%
    );
    color: $onyx !important;
  }
}

// Job Board Starts

.campus-desc-job-jb-card-img {
  height: 45px;
  width: auto;
  max-height: 45px !important;
  object-fit: contain;
  align-self: center;
  padding: 5px;
}

.campus-desc-jobs-icon {
  font-size: 14px;
  margin-right: 10px;
  color: $spacecadet;
}

.dashboard-saved-circle {
  font-size: 8px;
  font-weight: 500;
  color: $darkred;
  border: 1px solid $darkgray;
  border-radius: 50%;
  padding: 3px;
  text-align: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../../public/images/home/down-arrow.png");
}

.accordion-button::after {
  background-image: url("../../../public/images/home/down-arrow.png");
}

.campus-justify-text-job-desc {
  text-align: left;
  white-space: normal !important;
  color: $darkgray;
  font-size: 14px;
  font-weight: 400;
}

.campus-desc-job-skill-icon-bullet {
  height: 8px;
  color: $spacecadet;
  margin: 2px 2px 2px 5px;
}

.campus-about-website {
  font-size: 13px;
  font-weight: 600;
  color: $darkred;
  text-decoration: none;
}

.campus-desc-abt-job-jb-card-img {
  height: 55px;
  width: auto;
  max-height: 55px !important;
  object-fit: contain;
  align-self: center;
  padding: 5px;
}

.dashboard-button-more {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  color: $darkred !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $spacecadet !important;
  }
}
.dashboard-button-less {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 11px;
  font-weight: 600;
  font-style: italic;
  color: $spacecadet !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $darkred !important;
  }
}

.campus-accord-jobs-desc {
  margin-top: 1px;
  outline: 0;
  background: $white;
  padding: 1px;
  border: 1px solid $amethyst;
  box-shadow: 0px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
}

.campus-accord-jobs-desc button {
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: $cccalendartoday;
  transition: transform 0.5s;
  color: $darkgray !important;
  font-size: 14px;
  font-weight: 500;
  border: none !important;

  &:hover {
    background-color: $cccalendartoday;
  }

  &:not(.collapsed) {
    background-color: $cccalendartoday;
    color: $darkgray !important;
    font-size: 14px;
    font-weight: 500;
  }
}

.campus-accord-jobs-filter {
  margin-top: 5px;
  outline: 0;
  background: $white;
  padding: 1px;
  border: 1px solid $alto;
  box-shadow: 0px 0px 1px rgb(0 55 183 / 8%);
  border-radius: 8px;
}

.campus-accord-jobs-filter button {
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: $cccalendartoday;
  transition: transform 0.5s;
  color: $darkgray !important;
  font-size: 14px;
  font-weight: 500;
  border: none !important;

  &:hover {
    background-color: $cccalendartoday;
  }

  &:not(.collapsed) {
    background-color: $cccalendartoday;
    color: $darkgray !important;
    font-size: 14px;
    font-weight: 500;
  }
}

.campus-accord-jobs-text {
  font-size: 13px;
  font-weight: 500;
  color: $onyx;
}
.campus-subheader-jobtp-jobs {
  font-weight: 500;
  color: $onyx;
  font-size: 13px;
  border: 1px solid $alto !important;
  border-radius: 20px;
  padding: 3px 6px;
  margin: 1px 5px;
}
.campus-desc-job-rr-icon-bullet {
  height: 8px;
  color: $alto;
  margin: 1px 10px 2px 2px !important;
}
.dashboard-post-text {
  font-size: 15px;
  font-weight: 600;
  color: $onyx;
}

.dashboard-sub-post-text {
  font-size: 12px;
  font-weight: 400;
  color: $scorpion;
}
.dashboard-sub-post-text-lite {
  font-size: 12px;
  font-weight: 400;
  color: $darkgray;
}
.dashboard-group-text {
  font-size: 12px;
  font-weight: 600;
  color: $darkred;
}
.dasboard-icons {
  font-size: 14px;
  font-weight: 400;
  color: $darkred;
}
// Job Board Ends

.card-iphone-button {
  background-color: $onyx;
  border: 1px solid $onyx;
  border-radius: 40px;
  color: $white;
  padding: 8px 15px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
  cursor: pointer;
  box-shadow: 2px 5px 6px rgba(7, 7, 7, 0.08);
  &:hover {
    border-color: $darkred !important;
    color: $white;
    background-color: $darkred !important;
  }
  &:focus {
    box-shadow: none;
    border-color: $darkred !important;
    color: $white;
    background-color: $darkred !important;
  }
}

.campus-jobs-button {
  background-color: #013763 !important;
  border: 1px solid rgb(10, 64, 91) !important;
  border-radius: 7px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $white !important;
  padding: 5px 17px 6px 14px !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: #015193 !important;
  }

  &:hover {
    background-color: #015193 !important;
    color: $white !important;
  }
}
.campus-jobs-button-lite {
  background-color: transparent !important;
  border: 1px solid #013763 !important;
  border-radius: 7px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $onyx !important;
  padding: 5px 17px 6px 14px !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $onyx !important;
    background-color: transparent !important;
    border-color: #015193 !important;
  }

  &:hover {
    background-color: transparent !important;
    color: $onyx !important;
    border-color: #015193 !important;
  }
}

input[type="file"]::file-selector-button {
  background-image: linear-gradient(10deg, $scorpion 10%, $greenvogue 100%);
  border: none !important;
  border-radius: 2px;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(
      100deg,
      $peachorange 10%,
      $greenvogue 80%
    );
    color: $onyx !important;
  }
}
.upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.upload-box .upload-box-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-box-input[disabled] {
  cursor: not-allowed;
}
