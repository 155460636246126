@import '../../../../styles/scss/index.scss';

.xcelian-login-bg {
    content: "";
    background-image: url("../../../../../public/images/common/bg-exclusive.png");
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 110vh;
}
.login-align-vertical {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 2rem;
}
.xcelian-margin-login{
    margin:10px 6rem;
}
.xcelian-icon{
    height: 20px;
    width: 20px;
}
@media screen and (max-width: 480px) {
    .vertical-align{
        border: none;
        padding-top: 50px;
    }
    .login-card {
        width: 100% !important;
    }
    .xcelian-margin-login{
        margin:4rem 0;
    }
}
