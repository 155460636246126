@import "../../styles/scss/index.scss";

/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.06) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: scroll;
}

.modal-sm {
  background: white;
  border-radius: 8px;
  width: auto;
  max-width: 85%;
  height: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  margin-top: -6rem;
}
.modal-md {
  background: white;
  border-radius: 8px;
  width: 40%;
  min-width: 350px;
  height: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  margin-top: -4rem;
}
.modal-xl {
  background: white;
  border-radius: 8px;
  width: 65%;
  min-width: 600px;
  height: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  margin-top: 3rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-height: 90vh;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #f85002;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
    color: #f85002 !important;
  }
  &:hover {
    background-color: transparent !important;
    color: $onyx !important;
  }
}

.modal-content {
  // max-height: 400px;
  overflow-y: auto;
  border: none;
}
.title-modal {
  font-size: 16px;
  font-weight: 500;
  color: $onyx;
}
@media screen and (max-width: 576px) {
  .modal {
    max-width: 100%;
    margin: 0 10px;
    height: auto;
    margin-top: -7rem;
  }
}
