@import "../../../../styles/scss/index.scss";

.avatar-outer-card {
  border: 1px solid $amethyst;
  box-shadow: 0px 1px 2px rgba(20, 20, 21, 0.18);
  border-radius: 8px;
}

.avatar-user-profile-preview {
    margin-right: 30px;
    margin-bottom: 10px;
    width: 180px;
    height: 180px;
    padding: 2px;
    border: 1px solid $alto;
  }
.profile-image-btn{
    border: 0 none !important;
}
.profile-image-btn-border{
  border: 1px solid $peachorange !important;
}
.avatar-card{
  border: 1px solid $alto;
  background: $white;
  padding: 5px;
  border-radius: 20px;
}
.profile-image-btn{
  border: 0 none !important;
}

input[type=file].avatar {
  background: none !important;
  color: $dimgray;
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .padding-mob-top {
    padding-top: 35px !important;
  }
}