*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  /* scroll-behavior: smooth; */
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  /* overflow: auto; */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 480px) {
  html {
    /* scroll-behavior: smooth; */
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
}