@import '../../../../styles/scss/index.scss';

.xcelian-register-bg {
    content: "";
    background-image: url("../../../../../public/images/common/bg-exclusive.png");
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 110vh;
}

.register-card {
    // position: relative;
    // width: 80% !important;
    // height: auto;
    // background-color: #f9f9f9;
    // background-image: linear-gradient(
    //     105deg,
    //     rgb(255, 255, 255) 10%,
    //     #fdf6fc,
    //     #f6fdfb,
    //     rgb(222, 235, 251) 115%
    //   );
    border: 1px solid rgb(222, 235, 251);
    // box-shadow: 2px 4px 5px rgba(22, 24, 26, 0.09);
    border-radius: 20px;
    padding: 5px 4px;
}

// .register-card-header-footer {
//     background-image: linear-gradient(190deg,
//             $white 10%,
//             $amethyst 80%);
//     border: none;
// }