@import "../../styles/scss/index.scss";

.offcanvas {
  position: fixed;
  top: 0;
  right: -100%;
  width: 55%;
  height: 100%;
  background-color: #f7f9f9 !important;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  z-index: 1050 !important;
  overflow-y: scroll;
  // border: 1px solid $white;
}

.offcanvas.show {
  right: 0;
}

// .offcanvas-backdes {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(
//     21,
//     20,
//     20,
//     0.5
//   ); /* Semi-transparent dark background */
//   z-index: 1040 !important; /* Lower than offcanvas */
// }

.offcanvas-content {
  padding: 20px;
}

.offcanvas-close {
  position: absolute;
  top: 1px;
  right: 6px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: $darkred;
  padding: 2;
  margin: 0;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: transparent !important;
    // border: 1px solid $darkred;
  }

  &:hover {
    background-color: transparent !important;
    color: $onyx !important;
    // border: 1px solid $darkred;
  }
}

@media screen and (max-width: 480px) {
  .offcanvas {
    width: 100%;
  }
}
