@import "../../styles/scss/index.scss";

.accordion {
  // border: 1px solid #ddd;
  // border-radius: 4px;
  border: 1px solid #e9e9e4;
  border-radius: 15px;
  margin: 2px 5px;
  // padding: 0 12px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.open {
    .accordion-header {
      background-color: #fbfbf9;
      // background-color: transparent;
      border: none;
      margin: 0;
      padding: 15px 0;
    }
  }

  .accordion-header {
    color: $onyx;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0;
    background-color: #fbfbf9;
    border: none;
    margin: 0;
    padding: 15px 0;
    width: 100%;
    border-radius: 1px;
    transition: background-color 0.3s ease-in-out;
    border: none;

    h2 {
      margin: 0;
      font-size: 16px;
    }

    .accordion-toggle-open {
      font-size: 16px;
      color: $darkred;
    }
    .accordion-toggle-closed {
      font-size: 16px;
      color: $onyx;
    }
  }

  .accordion-content {
    padding: 10px;
    background-color: #fff;
    // border-top: 1px solid #ddd;
  }
}

.accordion-jobs {
  border: 1px solid #f4e7e7;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  // overflow: hidden;
  transition: all 0.3s ease-in-out;
  color: $onyx !important;
  background-color: #fcf8ff;
  &.open {
    .accordion-jobs-header {
      background-color: #fbf8ff;
      border: none;
      color: $onyx !important;
      padding: 8px 0;
    }
  }

  .accordion-jobs-header {
    background-color: #fbf8ff;
    padding: 8px 0;
    color: $scorpion !important;
    font-weight: 400;
    font-size: 14px;
    color: $suvagrey;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    border: none;

    .accordion-jobs-toggle-open {
      font-size: 14px;
      color: #013763;
      margin: 0 5px;
    }
    .accordion-jobs-toggle-closed {
      font-size: 14px;
      color: #f26603;
    }
  }

  .accordion-jobs-content {
    padding: 1px;
    background-color: transparent;
    margin: 0;
    padding: 0;
    // border-top: 1px dashed #00eff7;
  }
}
