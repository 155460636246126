@import '../../../styles/scss/index.scss';

.training-bg {
    content: "";
    margin-top: 90px;
    background-image: url("../../../../public/cx-trans.png");
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.training-intro-header{
    font-weight: 800;
}
.text-intro-training {
    color: $scorpion;
    font-size: 22px;
    font-weight: 500;
}
.image-intro-highlights{
    width: 80px;
    height: 80px;
}
.image-intro-highlights-3c{
    width: 120px;
    height: 80px;
}
.train-intro-min-margin{
    min-height: 270px !important;
}
.text-lite-grey-sub-train {
    color: $dimgray;
    // font-style: italic;
    font-size: 15px;
    // font-weight: 400;
  }
  .text-lite-grey-sub-train-strike {
    color: $dimgray;
    font-style: italic;
    font-size: 15px;
    font-weight: 500;
    text-decoration:line-through;
  }
.text-training-img {
    height: auto;
    width: 50px;
  }
.text-training-img-ha {
    height: auto;
    width: 50px;
    background-color: $onyx;
}
.train-blue-icon{
    color: $cerulean;
}
.fixed-top-modal {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11050; /* Ensure the modal is on top of the page */
    margin: 0; /* Remove any default margin */
    max-height: 100%; /* Make sure it doesn't overflow */
    overflow-y: auto; /* Allow scroll if modal content is too long */
  }
  