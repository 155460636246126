@import "../../../styles/scss/index.scss";

.image-intro {
  border-radius: 50px;
}

.bg-intro {
  background-image: linear-gradient(
    105deg,
    rgb(249, 222, 251) 10%,
    #f6fafd,
    #f6fafd,
    rgb(255, 255, 255) 85%
  );
  //   border-top-left-radius: 100px;
  //   border-bottom-right-radius: 150px;
  //   border-bottom-left-radius: 50px;
  // padding: 50px;
}
.text-intro-header {
  // color: $darkred;
  font-size: 40px;
  font-weight: 800 !important;
}
.text-intro {
  color: $scorpion;
  font-size: 20px;
  font-weight: 500;
}
.home-key-highlight-box {
  border: 1px dashed $alto;
  background-color: $cccalendartoday;
  border-radius: 4px;
  min-height: 150px;
  overflow: hidden;
  padding: 15px 0;
  font-weight: 400;
  font-size: 15px;
}
.image-intro-highlights {
  width: 40px;
  height: 40px;
}
.text-about {
  color: $scorpion;
  font-size: 14px;
  font-weight: 500;
}
.text-sub-about {
  color: $dimgray;
  font-size: 12px;
  font-weight: 400;
}
.about-card-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: $darkred;
}
.card-description {
  font-size: 1.1rem;
  font-weight: 500;
  color: $scorpion;
  margin-bottom: 20px;
}
.about-image-platform{
    border-radius: 40px;
    padding: 10px;
}
.text-sub-intro {
  color: $scorpion;
  font-size: 18px;
  font-weight: 400;
}
@media screen and (max-width: 480px) {
  .margin-icons-bottom {
    padding-bottom: 35px;
  }
  .margin-mob-contact-input {
    margin-top: 25px !important;
  }
}

// Footer styling

.text-footer-header {
  color: $white;
  font-size: 20px;
  font-weight: 500;
}
.text-footer-know-campus {
  color: $white;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.text-footer-desc {
  color: $white;
  font-size: 14px;
  font-weight: 400;
}
.training-text-360-header {
  color: $darkred;
  font-size: 16px;
  font-weight: 600;
}
.btn-footer-icon {
  border: 1px solid $white;
  border-radius: 5px;
  padding: 3px 5px;
  margin: 2px;
  min-width: 40px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    border: 1px solid $peachorange;
  }
}
.footer-wds-icon {
  color: $scorpion;
}
.copyright {
  color: $scorpion;
  font-weight: 300;
}
.text-copyright-mail {
  color: $scorpion;
  font-weight: 400;
  &:hover {
    color: $darkred;
  }
}
//   .text-footer-abou-count{
//     font-size: 35px;
//     color: $darkturquoise;
//     font-weight: 800;
//   }
.text-footer-abou-title {
  font-size: 25px;
  color: $white;
  font-weight: 700;
}
.imagepreek{
  font-size: 10px;
  color: #fffdfd;
  font-weight: 100;
}