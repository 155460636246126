@import "../../../../../../styles/scss/index.scss";

.access-pass {
  color: $salem;
  font-size: 15px;
}

.access-blocked {
  color: $darkred;
  font-size: 15px;
}
.admin-enquiry-button-approve {
  background: #442e51;
  border: none;
  color: $white !important;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  &:focus {
    outline: 0;
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }

  &:hover {
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}

.admin-enquiry-button-blocked {
  background: transparent !important;
  border: none;
  color: $darkred !important;
  font-size: 13px;
  font-weight: 700;
  padding: 3px 7px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  &:focus {
    outline: 0;
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
    color: #dd6d13 !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.message-column {
  min-width: 250px !important;
  text-align: left !important;
}
