@import "../../../styles/scss/index.scss";

.bg-campus {
  background-image: linear-gradient(
    105deg,
    rgb(242, 213, 213) 10%,
    #f6fafd,
    #fdead6,
    rgb(244, 251, 188) 85%
  );
  //   border-top-left-radius: 100px;
  //   border-bottom-right-radius: 150px;
  //   border-bottom-left-radius: 50px;
  // padding: 50px;
}
.campus-header {
  font-size: 40px;
  font-weight: 800;
}
.campus-op-header {
  font-size: 35px;
  font-weight: 800;
  color: $darkred;
}
.campus-sub-header {
  font-size: 20px;
  font-weight: 600;
}
.image-intro-campus{
  height: 70px;
  width: 70px;
}
.campus-home-button {
  background-color: #015193 !important;
  border: none;
  border-radius: 6px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $white !important;
  padding: 8px 15px !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: $darkred !important;
  }

  &:hover {
    background-color: $onyx !important;
    color: $white !important;
  }
}