@import url("https://fonts.googleapis.com/css?family=Poppins");

*,
*::before,
*::after {
  box-sizing: border-box;
}
.forgot-password-text{
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  color: $suvagrey;
  &:hover {
    color: $darkred;
  }
}
.correct-pass-key {
  color: $salem;
  font-size: 12px;
}
.icon-grey {
  color: $darkgray;
  font-size: 12px;
}

.wrong-pass-key {
  color: $darkred;
  font-size: 12px;
}
.close-button {
  font-size: 14px;
  color: $darkred;
}

.align-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.quest-menu-icon {
  color: $greenvogue;
}

.quest-menu-icon-size-sm {
  color: $suvagrey;
  font-size: 12px;
}
.text-lite {
  color: $oslogray;
  font-size: 14px;
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.alert-error {
  font-size: 16px;
  font-weight: 400;
  color: $darkred;
}
.input-checkbox-text-grey {
  color: $onyx;
  font-size: 15px;
  font-weight: 400;
}
.loader-campusxcel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $darkred;
}
.loader-container-none {
  position: fixed;
  top: 60%;
  left: 65%;
  transform: translate(-50%, -50%);
  // background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.spinner-img {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-image-size {
  height: 40px;
}

.text-center-aligned {
  text-align: center !important;
}

.align-text-justify {
  text-align: justify !important;
}

.tooltip {
  position: absolute;
}

.tooltip-inner {
  background: $white;
  border: 1px solid $darkred;
  color: $onyx;
  font-weight: 500;
}

.tooltip.show .arrow::before {
  border-color: $darkred transparent $darkred transparent;
}
.campus-header-with-bg {
  color: $onyx !important;
  background-color: $seashell !important;
  font-size: 15px;
  font-weight: 600;
  margin: 5px 4px !important;
  padding: 5px 10px;
}
.cx-contact-card {
  border: 1px dashed $alto;
  border-radius: 20px;
  padding: 5px 15px;
}
.cx-main-header-img-card {
  border: 1px dashed $alto;
  border-radius: 50%;
  padding: 10px;
  background-color: $seashell;
  height: 50px;
  width: 50px;
}
.recruit-main-header-img-card {
  border: 1px dashed $alto;
  border-radius: 10px;
  padding: 5px;
  background-color: $seashell;
  height: 40px;
  width: 40px;
}
.recruit-main-header-img-card-none {
  border: none;
  border-radius: 10px;
  padding: 5px;
  background-color: $seashell;
  height: 40px;
  width: 40px;
}
.cx-main-header-text {
  font-size: 15px;
  font-weight: 600;
  color: $scorpion;
}
.cx-public-client-text {
  font-size: 15px;
  font-weight: 600;
  color: #5d0e77;
}
.hr-vertical {
  border-left: 2px dashed $alto;
}
.hr-dasshed-lite {
  border: 1px dashed $suvagrey;
}
.hr-lite {
  border: .02rem solid #bdbaa7;
}
.my-1 {
  margin: 5px 0;
}

.my-2 {
  margin: 10px 0;
}

.mx-1 {
  margin: 0 5px;
}

.mx-2 {
  margin: 0 10px;
}

.mx-3 {
  margin: 0 1rem;
}

.mx-4 {
  margin: 0 2rem;
}

.mx-5 {
  margin: 0 3rem;
}

.ml {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}
.ml-5 {
  margin-left: 5rem;
}
.mr {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}
.pl {
  padding-left: 0.5rem;
}
.pl-1 {
  padding-left: 1rem;
}

.mp {
  padding: 0.3rem;
}

.mp-1 {
  padding: 1rem;
}

.mp-2 {
  padding: 2rem;
}

.mptb {
  padding: 0.3rem 0;
}

.mptb-1 {
  padding: 1rem 0;
}

.mptb-2 {
  padding: 2rem 0 !important;
}

.ptop-1 {
  padding-top: 1rem;
}

.ptop-2 {
  padding-top: 1.5rem;
}

.ptop-3 {
  padding-top: 2rem;
}

.pb-1 {
  padding-bottom: 10px;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mb {
  margin-bottom: 5px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mt {
  margin-top: 5px;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mlr-1 {
  padding: 0 1rem;
}

.mlr-2 {
  padding: 0 2rem;
}

.margin-zero {
  margin: 0 !important;
}

.padding-zero {
  padding: 0 !important;
}

.squares {
  animation: move-left-right 1s infinite;
  background: $dimgray;
  background: -webkit-linear-gradient(0deg, $dimgray 0%, $darkred 100%);
  background: -o-linear-gradient(0deg, $dimgray 0%, $darkred 100%);
  background: -moz-linear-gradient(0deg, $dimgray 0%, $darkred 100%);
  background: linear-gradient(0deg, $dimgray 0%, $darkred 100%);
  position: absolute;
  transition: 0.5s ease-out;
  overflow: hidden;
  border-radius: 20%;
}

.squares.square {
  animation: move-left-right 3s infinite !important;
  height: 2000px;
  width: 200px;
  opacity: 0.1;
  // left: 32%;
  // bottom: 29%;
}

.squares.square2 {
  animation: move-left-right 3s infinite;
  width: 300px;
  height: 300px;
  right: -0%;
  bottom: 0%;
  opacity: 0.1;
}

.squares.square3 {
  animation: move-left-right 3s infinite;
  width: 250px;
  height: 250px;
  left: -5%;
  bottom: 10%;
  opacity: 0.1;
}

.card-shadow {
  border: 1px solid $amethyst;
  box-shadow: 0px 4px 10px rgba(20, 20, 21, 0.38);
  border-radius: 8px;
}
.card-border {
  border: 1px solid $amethyst;
  border-radius: 5px;
  padding: 2px;
  box-shadow: 0px 5px 7px rgba(20, 20, 21, 0.38);
}

.round-details-card-icon {
  font-size: 1.1em;
  width: 2.6em;
  text-align: center;
  line-height: 2.6em;
  background: $white;
  color: $darkred;
  border: 1px solid $suvagrey;
  border-radius: 1.9em;
}
.chip-box {
  padding: 4px 6px;
  margin: 0;
  align-items: center;
  border: 1px solid $alto !important;
  border-radius: 3px !important;
  color: $onyx;
  font-size: 14px;
  font-weight: 500;
  background-color: $cccalendartoday !important;
  width: auto !important;
}
.cx-exclusive-bg {
  content: "";
  background-image: url("../../../public/images/common/bg-exclusive.png");
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.collapse-bg {
  background-color: $cccalendartoday;
  padding: 5px 10px;
  border-radius: 5px;
}
.dashboard-thread-text {
  font-size: 15px;
  font-weight: 400;
  color: $scorpion;
}
.form-card {
  border: 1px solid $amethyst;
  border-radius: 15px;
}
.border-radius-card {
  border-radius: 50%;
}
.form-card-body {
  margin: 0;
  padding: 0;
}
.border-none {
  border: none !important;
  background-color: transparent;
}

.text-line {
  position: relative;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 800;
  color: $suvagrey;
}

.text-line::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-left: 8px;
  width: 40%;
  border-top: 2px dotted #c3bcbc;
  transform: translateY(-50%);
}
.line-height-1 {
  line-height: .9;
}
.line-height-2 {
  line-height: 1.5;
}
.line-height-3 {
  line-height: 2.0;
}
// .popover{
//   --bs-popover-arrow-height: 0 !important;
//   border-color: rgb(226, 229, 228);
//   box-shadow: 0px 4px 10px rgba(20, 20, 21, 0.38);
//   max-height: 30vh !important;
//   // overflow-y:scroll !important;
// }
// DOT Loader - Start
.dot-loader {
  gap: 3px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #ea6302;
  border-radius: 50%;
  animation: dot-blink 1s infinite both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}
// DOT Loder - End