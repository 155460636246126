@import "../../../styles/scss/index.scss";

.bg-ats {
  background-image: linear-gradient(
    105deg,
    rgb(223, 251, 222) 10%,
    #f6fafd,
    #f6fafd,
    rgb(255, 255, 255) 85%
  );
  //   border-top-left-radius: 100px;
  //   border-bottom-right-radius: 150px;
  //   border-bottom-left-radius: 50px;
  // padding: 50px;
}
.ats-header {
  font-size: 40px;
  font-weight: 800;
}
.ats-op-header {
  font-size: 35px;
  font-weight: 800;
  color: $darkred;
}
.ats-sub-header {
  font-size: 20px;
  font-weight: 600;
}
.image-intro-ats{
  height: 70px;
  width: 70px;
}
.campus-sales-button {
  background-color: $onyx !important;
  border: none;
  border-radius: 7px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $white !important;
  padding: 8px 20px 8px 18px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: #015193 !important;
  }

  &:hover {
    background-color: #015193 !important;
    color: $white !important;
  }
}