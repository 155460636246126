@import "../../../../styles/scss/index.scss";

.recruit-bg {
  background-color: #f1f5ff;
  border-radius: 10px;
  padding: 25px 2px;
}

.jobs-template-icon-size{
  height: 22px;
  width: 22px;
}
.size-client-logo{
  height: 30px;
  width: auto;
}
.recruit-job-bg {
  background-color: #fffaf9;
  border-radius: 10px;
  padding: 15px 5px;
  min-height: 90vh;
}
.recruit-job-border {
  border-left: 2px dashed #fae3d6;
  min-height: 5rem;
  margin-left: 10px;
}
.recruit-card {
  padding: 2px 3px;
  border: none;
  border-radius: 25px;
  background-color: $white;
  color: $onyx;
  box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
}
.job-add-card {
  padding: 2px 3px;
  margin: 2px 3px;
  border: none;
  border-left: 1px dashed #efebf5;
  border-radius: 1px;
  background-color: $white;
  color: $onyx;
  // box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
}
.job-pre-card {
  padding: 3px;
  border: none;
  border-radius: 25px;
  background-color: $white;
  color: $onyx;
  box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
}
.recruit-image-job-add-margin {
  margin-top: 5rem;
}
.irregular-recruit-img {
    border-top-left-radius: 10px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 100px;
}

.vertical-stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1.5rem;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .65;
}

.circle.active {
  background-color: #f9d6d6;
  border: 2px dashed #0f1c2a;
  opacity: 1;
}

.circle img {
  width: 30px;
  height: 30px;
}

.bar {
  width: 4px;
  height: 45px;
  background-color: #dddddd;
  margin-top: 10px;
}

.bar.active {
  background-color: #0f1c2a;
}
.margin-state-desk{
  margin-top: 15px;
}
.job-button-notify {
  background: transparent;
  border: none;
  color:  #dd6d13!important;
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  padding: 0;
  margin-bottom: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: #9301e8 !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.job-applicant-text{
  font-weight: 600;
  color: $onyx;
  font-size: 14px;
}
.job-button-action {
  background: #921900;
  border: none;
  color:  $white!important;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 7px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  &:focus {
    outline: 0;
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }

  &:hover {
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}

.job-button-approve {
  background: #442e51;
  border: none;
  color:  $white!important;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 7px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  &:focus {
    outline: 0;
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }

  &:hover {
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.card-priv-joblist {
  border: 1px solid rgba(197, 211, 211, 0.88);
  border-radius: 10px;
  padding: 4px;
  box-shadow: 0 3px 5px rgba(10, 10, 10, 0.3);
}
.button-transparent-jobs {
  background: transparent;
  border: none;
  color: #878096 !important;
  font-size: 13px;
  font-style: italic;
  padding: 0;
  margin-bottom: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $darkred !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.button-transparent-buy {
  background: transparent;
  border: none;
  color: #1a2fb3 !important;
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  padding: 0;
  margin-bottom: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $darkred !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.cx-jobs-view-card {
  border: none;
  border-radius: 10px;
  padding: 5px 1px;
  background-color: $white;
  box-shadow: 0 0px 1px rgba(41, 38, 38, 0.68);
}
.cx-jobs-dashed-card-main {
  border: 1px dashed #f9f3fd;
  border-radius: 2px;
  padding: 5px 1px;
  background-color: #fafffe;
}
.card-joblist-pvt-appr {
  border: 1px solid rgba(62, 62, 61, 0.08);
  border-radius: 10px;
  padding: 4px;
  box-shadow: 0 5px 6px rgba(20, 20, 19, 0.3);
  // min-height: 220px;
}
.cx-jobs-dashed-card {
  border: 1px dashed $alto;
  border-radius: 20px;
  padding: 5px 5px;
}
.badge-container{
  display: flex;
  flex-wrap: wrap;
}
.jobs-campus-input {
  border: 1px solid $litered !important;
  background: $white !important;
  border-radius: 7px !important;
  height: 45px !important;
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: $darkred !important;
    color: $scorpion;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid $darkred !important;
  }
  &:disabled {
    border: 1px solid $cccalendartoday !important;
    background: $cccalendartoday !important;
  }
}

.campus-job-radio-box {
  color: $darkred;
  // margin-top: 5px;
  outline: 1px solid $darkred !important;
  border: 1px solid $white !important;
  box-shadow: none; //0 0 1px $darkred;
  // margin-right: 10px;
  // width: 1px;
  // height: 13px;
  // border-radius: 50% !important;
}

.jobs-check-text {
  color: $dimgray;
  font-size: 14px;
  font-weight: 500;
  &:disabled {
    color: $dimgray !important;
  }
}
.form-check-job-text {
  color: $dimgray;
  font-size: 13px;
  font-weight: 400;
}
.campus-job-radio-box:checked {
  background-color: $darkred !important;
  border: 1px solid $darkred !important;
  box-shadow: none;
  &:disabled {
    border: 1px solid $cccalendartoday !important;
    background: $cerulean !important;
  }
}

.campus-job-radio-box:focus,
.label::after,
.label.campus-job-radio-box:focus,
.campus-job-radio-box:checked::after,
.campus-job-radio-box:not(.disabled):not(.disabled):active:focus {
  color: $white;
  border: 1px solid $darkred !important;
  outline: 1px solid $white !important;
  box-shadow: none;
  // border: 1px solid $white !important;
  // box-shadow: 0 0 0 .1rem  $white !important;
}
.job-icon-pending{
  color: #45556c;
  font-size: 12px;
}
.job-icon-live{
  color: #067a15;
  font-size: 12px;
}
.job-icon-block{
  color: #dd3813;
  font-size: 12px;
}
.chip-box-jobs-pvt {
  padding: 4px 6px;
  margin: 0;
  align-items: center;
  border: 1px solid #d4d0d0 !important;
  border-radius: 5px !important;
  color: $onyx;
  font-size: 13px;
  font-weight: 500;
  background-color: #fafffa !important;
  width: auto !important;
}
.margin-mob-desk-pirv-jobs{
  margin: 2px 3px;
}
.interview-logo-round {
  border: none;
  box-shadow: 0px 7px 10px rgba(20, 20, 21, 0.38);
  border-radius: 50%;
  padding: 5px 1px;
}
.form-card-interview-parsing {
  border: 1px solid $alto;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(20, 20, 21, 0.18);
}

@media screen and (max-width: 480px) {
  .cx-jobs-dashed-card-main {
    padding: 5px 0 !important;
  }
  .margin-mob-desk-pirv-jobs{
    margin: 1px 0;
  }
  .margin-state-desk{
    margin-top: 1px;
  }
  .margin-list-appr-job-mob{
    margin-top: 1rem;
  }
  .margin-list-search-job-mob{
    margin-top: 2rem;
  }
}
// SunEditor Formating
// .se-modal-title {
//   color: #0f1c2a !important;
//   font-size: 16px;
//   font-weight: 500;
// }