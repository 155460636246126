@import "../../../styles/scss/index.scss";

.xcelian-template-bg-active {
  // background-color: #d4fcbe !important;
  border: 1px solid #e36f02;
}

.xcelian-left-border {
  background-color: #fffffc;
  border-radius: 8px;
  padding: 2px 10px;
  border: 1px solid #fcf3f3;
  min-height: 110vh;
}
.xcelian-right-border {
  background-color: #eefffd;
  padding: 2px 3px;
  border-right: none;
  min-height: 110vh;
}
.template-design {
  border: 1px solid #c4c1c4;
  border-radius: 7px;
  padding: 2px 3px;
  box-shadow: 2px 2px 3px rgba(22, 24, 26, 0.3);
}
.xcelian-card {
  padding: 2px 3px;
  border: none;
  border-radius: 25px;
  background-color: $white;
  color: $onyx;
  box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
}
.xcelian-card-form {
  padding: 2px;
  border: 1px solid #cccece;
  border-radius: 15px;
  background-color: $white;
  color: $onyx;
}
.xcelian-card-template {
  // padding: 2px;
  border: 1px solid #cccece;
  border-radius: 1px;
  background-color: $white;
  color: $onyx;
  &:hover {
    border: 1px solid #f77002 !important;
    opacity: .68;
  }
}
.xcelian-input {
  border: 1px solid $alto !important;
  background: $white !important;
  border-radius: 7px !important;
  height: 40px !important;
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    box-shadow: none;
    border-color: #b2dcdc !important;
    color: $scorpion;
    background-color: $white !important;
  }
  &:hover {
    border: 1px solid #b2dcdc !important;
  }
  &:disabled {
    border: 1px solid $alto !important;
    background: $cccalendartoday !important;
  }
}
.xcelian-input::placeholder {
  color: #aa9d9d;
  opacity: 1;
}

.xcelian-upload-box {
  background: #442e51;
  border: none;
  color:  $white!important;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 7px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  &:focus {
    outline: 0;
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }

  &:hover {
    background-color: transparent;
    color: $white !important;
    background: #dd6d13;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.xcelian-upload-box .xcelian-upload-box-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.xcelian-upload-box-input[disabled] {
  cursor: not-allowed;
}

.cx-xcelian-dashed-card {
  border: 1px dashed $alto;
  border-radius: 2px;
  padding: 5px 5px;
}
.xcelian-resume-org-text {
  font-size: 15px;
  font-weight: 600;
  color: $britvil;
  font-style: italic;
}
.xcelian-resume-project-header {
  font-size: 14px;
  font-weight: 500;
  color: $dimgray;
}
.xcelian-resume-project-header-dis {
  font-size: 14px;
  font-weight: 400;
  color: $alto;
  font-style: italic;
}
.xcelian-resume-project-icon {
  font-size: 14px;
  color: #d8590a;
}
.xcelian-resume-project-role-icon {
  font-size: 10px;
  color: #cec0b7;
}
.lang-border{
  border: 1px solid #f5e4e4;
  border-radius: 3px;
  padding: 2px;
  margin: 2px;
}
.width-margin-resume-input{
  margin: 2px 6px !important
}
.xcelian-resume-covverletter {
  font-size: 14px;
  font-weight: 600;
  color: #1b161e;
}
.xcelian-resume-covverletter-act {
  font-size: 14px;
  font-weight: 500;
  color: #e36f02;
}
.xcelian-resume-covverletter-width {
  min-width: 450px !important;
}
.cert-img-e-res{
  height: 100px;
  width: 100px;
}
@media screen and (max-width: 480px) {
  .width-margin-resume-input{
    margin: 2px 0 !important
  }
}
