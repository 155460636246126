@import "../../../../styles/scss/index.scss";

.onboard-margin-top {
  margin-top: 20px;
}
.campus-row-onboard-tab-mobile {
  display: none;
}
.onboard-admin-bg {
  background-color: #f9f1ff;
  border-radius: 10px;
  padding: 25px 2px;
  // background-image: url('../../../../../public/images/ats/add-client.png');
}
.onboard-admin-card {
  padding: 2px 3px;
  border: none;
  border-radius: 25px;
  background-color: $white;
  color: $onyx;
  box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
}

.campus-row-onboard-tab {
  display: table-column;
  border-bottom: 1px solid #ececec;
}

.campus-column-onboard-tab-left {
  float: left;
}
.campus-column-onboard-tab-right {
  float: right;
}
.campus-onboard-nav-tabs {
  color: $suvagrey !important;
  // background-color: $cccalendartoday !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px !important;
  // padding: 1px 15px;
  padding: 5px 20px;
  background: #f4f1f1 !important;
  border-left: 1px solid #ececec !important;
  border-right: 1px solid #ececec !important;
  border-top: 1px solid #ececec !important;
}

.campus-onboard-nav-tabs.active {
  color: $darkred !important;
  background: $white !important;
  letter-spacing: 1px;
  border-left: 1px solid $darkred !important;
  border-right: 1px solid $darkred !important;
  border-top: 1px solid $darkred !important;
  border-bottom: 1px solid $darkred !important;
}

.campus-onboard-button {
  color: $scorpion !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  margin: 4px 1px !important;
  width: 100%;
  padding: 10px 1px;
  background: #fbf1fc !important;
  // border-left: 1px solid #ececec !important;
  // border-right: 1px solid #ececec !important;
  // border-top: 1px solid #ececec !important;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.campus-onboard-button.active {
  color: $onyx !important;
  // background: $white !important;
  letter-spacing: 1px;
  border: 1px solid $cccalendartoday !important;
  border-radius: 15px;
  box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
  background-image: linear-gradient(150deg,
  #f9eafb 50%,
  #d6b6fb 90%) !important;
}

.campus-tab-onboard-icon {
  font-size: 17px;
  margin-right: 10px;
}

.campus-tab-onboard-icon-img {
  height: 30px;
  width: 30px;
}
.campus-onboard-org-img {
  height: 50px;
  width: 65px;
}
.proj-card-bg {
  padding: 2px 3px;
  border: none;
  border-radius: 5px;
  background-color: $cccalendartoday;
  color: $onyx;
}
.proj-card-bg-border {
  padding: 2px 3px;
  border: 1px solid $litered;
  border-radius: 10px;
  background-color: $white;
  color: $onyx;
}
.proj-sub-card-bg {
  padding: 5px;
  border: 1px solid $alto;
  border-radius: 5px;
  background-color: $white;
  color: $onyx;
}

.proj-header-text {
  font-size: 16px;
  font-weight: 500;
}
.proj-text {
  font-size: 15px;
  font-weight: 400;
}
// .proj-word-break{
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
.campus-chip-box {
  padding: 2px 8px;
  margin: 5px;
  border: 1px solid $darkred !important;
  border-radius: 5px !important;
  color: $scorpion;
  background-color: $white !important;
  width: auto !important;
}
.proj-text-color {
  font-size: 15px;
  font-weight: 400;
  color: $spacecadet;
}
.proj-chip-box {
  padding: 2px 8px;
  margin: 5px;
  border: 1px solid $darkred !important;
  border-radius: 35px !important;
  color: $scorpion;
  background-color: $white !important;
  width: auto !important;
}
.image-org-logo {
  height: 90px;
  width: 130px;
}
.org-header-text {
  font-size: 16px;
  font-weight: 600;
  color: $onyx;
}
.onboard-chip-box {
  padding: 4px 6px;
  margin: 0;
  align-items: center;
  border: 1px solid #bcbabe !important;
  border-radius: 15px !important;
  color: $onyx;
  font-size: 14px;
  font-weight: 500;
  background-color: $white !important;
  width: auto !important;
  // box-shadow: 0 6px 10px rgba(10, 10, 10, 0.7);
}
.onboard-bg-box {
  padding: 4px 6px;
  margin: 0;
  align-items: center;
  border-left: 3px solid #ecf5fa !important;
  box-shadow: none;
  min-height: 110vh;
  border-radius: 1px;
  background-color: $white;
}
.bg-client-check-register{
  background-color: $cccalendartoday;
  border: 1px dashed #ecf5fa !important;
  padding: 10px 6px;
  border-radius: 10px;
}
.onboard-main-margin-top {
  margin-top: 1px;
}
.onboard-horz-comp {
  display: flex;
  flex-direction: row;
}
.onboard-refresh-button {
  color: #02a63e !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #ecfaf1;
  border-radius: 5px;
  background-color: transparent;
  margin: 5px 10px;
  padding: 5px 10px;
  &:hover{
    background-color: #171817;
    color: #02fb5d !important;
    border: 1px solid #171817;
  }
}
@media screen and (max-width: 480px) {
  .onboard-main-margin-top {
    margin-top: 40px;
  }
  .onboard-chip-box {
    padding: 4px 4px;
    border: none;
    box-shadow: none;
  }
  .campus-row-onboard-tab-mobile {
    display: block;
    margin-top: 10px;
  }
  .onboard-horz-comp {
    display: flex;
    flex-direction: column;
  }
  .campus-row-onboard-tab {
    display: none;
  }

  .campus-tab-onboard-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .campus-onboard-tab {
    border: none !important;
  }
  .campus-onboard-nav-tabs {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 1px 1px !important;
    padding: 5px 20px !important;
    background: #ececec !important;
    border: 1px solid $litered !important;
  }
  .campus-onboard-nav-tabs.active {
    color: $darkred !important;
    background: $white !important;
    letter-spacing: 1px;
    border: none !important;
  }
  .campus-tab-onboard-icon-img {
    height: 45px;
    width: 45px;
    margin: 0 5px;
  }
}
@media screen and (max-width: 480px) {
  .campus-onboard-nav-tabs.active {
    color: $darkred !important;
    background: #ffffff !important;
    letter-spacing: 1px;
    border: 1px solid #ff9d9a !important;
    border-bottom: 5px solid $darkred !important;
  }
}