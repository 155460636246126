@import "../../../styles/scss/index.scss";

.cx-tabs {
  display: flex;
  // border-bottom: 2px solid #faf0f0;
  margin-bottom: 20px;
}
.post-image-dim{
  width: 100%;
  // max-height: 350px;
}
.cx-tab {
  flex: 1;
  text-align: center;
  padding: 1px;
  margin: 2px 5px;
  cursor: pointer;
  background-color: white;
  font-weight: 600;
  font-size: 14px;
  color: $suvagrey;
  // border-right: 10px solid #f4f4f4;
  box-shadow: 0px 1px 4px rgba(34, 35, 35, 0.4);
  border-radius: 20px;
}

.cx-tab:last-child {
  border-right: none;
}

.cx-tab img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto 1px;
}

.cx-tab.active {
  background-color: #676464;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid #01fa1a;
}

.cx-tab.active img {
  filter: brightness(0) invert(1);
}

.cx-tab-content {
  display: none;
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
}

.cx-tab-content.active {
  display: block;
}
.border-right-dash {
  border-right: 1px dashed #ccc;
  padding-right: 10px;
}
.dasboard-form-card {
  padding: 5px 2px;
  background: $white;
  border: 1px solid $alto;
  border-radius: 15px !important;
  box-shadow: 2px 2px 3px rgba(22, 24, 26, 0.07);
}
.dashboard-home-icon {
  height: 30px;
  width: 30px;
}
.modal-header-text {
  color: $darkgray;
  font-size: 16px;
  font-weight: 500;
}
.text-lite-grey-comments {
  color: $suvagrey;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 1px;
}
.cx-avatar-user-small-post {
  height: 30px;
  width: 30px;
}
.dashboard-post-text-replies {
  color: $darkred !important;
  font-size: 11px;
  font-weight: 600;
}
.dashboard-post-text-icon {
  color: $alto;
  font-size: 10px;
  font-weight: 500;
}
.dashboard-post-text-grey {
  color: $scorpion;
  font-size: 11px;
  font-weight: 500;
}
.dashboard-link {
  text-decoration: none !important;
}
.dashboard-button-icon[disabled] {
  background: transparent;
  color: $dimgray !important;
}
.dashboard-button-icon {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    background-color: transparent;
  }
}
.text-lite-grey-date {
  color: $suvagrey;
  font-size: 12px;
  font-weight: 400;
}
.text-lite-grey-desg {
  color: $suvagrey;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 1px;
}
// .dasboard-comment-card {
//   padding: 5px 10px;
//   background: $cccalendartoday;
//   border: 1px solid $alto;
//   border-radius: 3px !important;
//   box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
//   border-radius: 5px;
// }

.dashboard-hr-color {
  color: $alto;
  margin: 5px 5px;
}

.dashboard-comment-bg {
  background: $cccalendartoday;
  padding: 15px 5px;
}
.author-card {
  background-color: rgb(1, 68, 97);
  color: $white;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 4px;
  border-radius: 3px;
}
.store-icon {
  color: $cerulean !important;
  margin: 2px;
  padding: 3px;
  font-size: 20px;
}

.dashboard-follow-text {
  font-size: 12px;
  font-weight: 700;
  color: $scorpion;
  font-style: italic;
  &:hover {
    background-color: transparent;
    color: $spacecadet !important;
  }
}
.dashboard-follow-text-likes {
  font-size: 12px;
  font-weight: 500;
  color: $scorpion;
}
.css-1fdsijx-ValueContainer {
  padding: 2px 15px !important;
}

.dasboard-comments-card {
  border: 1px dotted $alto;
  background-color: $cccalendartoday;
  border-radius: 5px;
  box-shadow: 0px 2px 1px rgb(0 55 183 / 8%);
}
.dashboard-send-bottom {
  margin-bottom: 1rem !important;
}
.comming-soon-margin {
  margin-top: 20vh;
}
.dashboard-close-button {
  font-size: 14px;
  color: $peachorange;
}

.dashboard-close-text {
  font-size: 12px;
  color: $darkgray;
}
@media screen and (max-width: 480px) {
  .margin-mob-dash {
    padding-top: 60px !important;
  }
}
