@import "../../../styles/scss/index.scss";

.news-bg {
  content: "";
  margin-top: 100px;
}
.public-news-header-text {
  color: #421d92 !important;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}
.public-newsletter-button-header {
  background: transparent;
  border: none;
  color: #0d1b4f !important;
  text-decoration: underline #bbc9fa;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  padding: 0;
  margin-bottom: 7px;
  text-align: start !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: rgb(73, 4, 223) !important;
    text-decoration: underline #0d1b4f;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}

.public-subscribe-button {
  background: transparent;
  border: none;
  color: $darkred !important;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  padding: 0;
  margin-bottom: 7px;
  text-align: justify !important;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: #878096 !important;
  }
}

.cards-news-public {
  padding: 7px 10px;
  border: none;
  border-bottom: 1px solid #f0eeee;
  border-radius: 5px;
  background-color: $white;
  color: $onyx;
  text-align: center;
  margin-bottom: 10px;
}

.public-newsletter-img {
  max-width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.public-newsletter {
  /* Ensure that the container does not overflow horizontally */
  overflow: hidden;
  max-width: 100%;
}

.public-newsletter img,
.public-newsletter video,
.public-newsletter iframe {
  /* Ensure media elements like images, videos, and iframes fit within the container */
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the images/videos horizontally if needed */
}

.public-newsletter iframe {
  width: 100%; /* Ensure iframes take the full width of the container */
  height: auto; /* Adjust the height according to aspect ratio if needed */
}

.public-newsletter * {
  box-sizing: border-box; /* Ensure all child elements respect the padding/border */
}

.input-wrapper-newsletter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.input-wrapper-newsletter input {
  width: 100%;
  padding: 10px 100px 10px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.campus-button-newsletter {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 2px 2px;
  padding: 0 15px;
  background-color: #333; /* Dark background */
  color: #fff;
  border: none;
  border-radius: 4px;
  // border-radius: 0 4px 4px 0; /* Round the right corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping */
}

.campus-button-newsletter:hover {
  background-color: #555; /* Lighter dark color on hover */
}

.input-wrapper-newsletter input::placeholder {
  color: #aaa; /* Placeholder text styling */
}

.input-wrapper-newsletter input:focus {
  outline: none;
  border-color: #333;
}
.share-icon{
  font-size: 15px;
  color: #f78c00;
}