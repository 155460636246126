@import "../../styles/scss/index.scss";

.campus-display-device-mobile {
  display: none;
}

.campus-display-device {
  display: block;
}
.campus-exclusive-margin-left {
  margin-left: 6rem;
}
.campus-exclusive-header {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 1030;
  padding-bottom: 1rem;
  // margin-bottom: 1rem;
  border-bottom: 1px solid $alto;
  box-shadow: 10px 1px 15px rgba(141, 142, 144, 0.18);
}

.campus-float-right {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  flex: 1;
}

.nav-header-text {
  color: $suvagrey !important;
  font-size: 18px;
}
.nav-header-acad-text {
  color: $onyx !important;
  font-size: 14px;
  font-weight: 500;
}
.campus-header-icon {
  color: $suvagrey !important;
  font-size: 16px;
  margin: 2px;
  background: transparent;
  border: none;
  &:hover {
    color: $darkred !important;
  }
}
.campus-header-icon-msg {
  color: $peachorange !important;
  font-size: 16px;
  margin: 0 2px;
  &:hover {
    color: $darkred !important;
  }
}

.margin-button {
  padding: 0;
  margin: 3px 15px;
}

.campus-image-logo {
  width: auto;
  height: 35px;
  padding: 2px 0;
  margin-left: 40px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-link-notification {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1px 5px;
}
.menu-link-notification:hover {
  background-color: $cccalendartoday;
  padding: 1px 5px;
}

.border-bottom-nav {
  border: 0.01em solid $peachorange !important;
  margin: 1px !important;
}

.menu-link-notification:hover .menu-text-notification {
  text-decoration: underline;
}

.campus-notification-txt {
  color: $onyx !important;
  font-weight: 600;
}
.campus-notification-icon {
  color: $suvagrey !important;
  font-weight: 600;
}
.campus-footer-visible {
  display: none;
}
.campus-acad-logo {
  width: auto;
  height: 45px;
}
.academy-edit-icon {
  font-size: 12px;
  color: $darkgray;
}
.recruit-nav-display {
  width: auto;
  height: 45px;
}
.recruit-avatar-user {
  margin-right: 30px;
  width: 50px;
  height: 50px;
}
.recruit-toggle-nav {
  border: 1px solid $peachorange;
  border-radius: 5px;
  padding: 5px 10px;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    background-image: linear-gradient(
      100deg,
      $cccalendartoday 60%,
      $peachorange 100%
    );
    color: $onyx !important;
  }
}
.recruit-close-btn-mobile {
  font-size: 35px !important;
  color: $greenvogue;
}

.recruit-open-btn-mobile {
  font-size: 30px !important;
  color: $onyx;
}
.recruit-popover {
  padding: 0 !important;
}
.margin-recruit {
  padding-top: 5.5rem !important;
}
.recruit-button-icon {
  background: transparent;
  border: none;
  color: $scorpion !important;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    background-color: $cccalendartoday;
    color: #322561 !important;
  }
}
.recruit-menu-img-cx{
  height: 35px !important;
  width: 35px !important;
  padding: 5px;
  border: 1px dashed #d1cece;
  border-radius: 5px;
  background-color: #fff9f9;
}
.recruit-size-adjust{
  overflow-y: scroll;
  height: 80vh;
}
@media screen and (max-width: 480px) {
  .margin-mob-all {
    padding-top: 40px !important;
  }
  .margin-mob-top {
    padding-top: 40px !important;
  }
  .campus-display-device {
    display: none;
  }

  .campus-display-device-mobile {
    display: block;
  }

  .campus-image-logo-mobile {
    width: auto;
    height: 45px;
  }

  .campus-nav-bg {
    box-shadow: none !important;
  }

  .margin-logo {
    margin: 0 !important;
    padding: 0 !important;
  }
  .campus-avatar-user {
    margin-right: 30px;
    width: 50px;
    height: 50px;
  }
  .campus-toggle {
    border: 1px solid $peachorange;
    border-radius: 5px;

    &:focus {
      outline: 0;
      box-shadow: none !important;
    }

    &:hover {
      background-image: linear-gradient(
        100deg,
        $cccalendartoday 60%,
        $peachorange 100%
      );
      color: $onyx !important;
    }
  }

  .campus-close-btn-mobile {
    font-size: 35px !important;
    color: $greenvogue;
  }

  .campus-open-btn-mobile {
    font-size: 30px !important;
    color: $onyx;
  }

  .container-fluid {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .campus-collapse-bg {
    margin: 5px 0;
    background: $darkred;
  }
  .sadmin-collapse-bg {
    margin: 10px 0;
    background: $white;
    box-shadow: 0 3px 5px rgba(45, 33, 76, 0.7);
  }
  .sadmin-link-txt {
    color: $onyx;
    font-size: 20px;
    font-weight: 600;
  }
  .ml-auto {
    margin-left: 15px;
  }
  .campus-menu-icon {
    color: $white;
    font-size: 14px;
    margin-bottom: 1px;
  }

  .campus-link-txt {
    color: $white;
    font-size: 17px;
    font-weight: 600;
  }

  .campus-link-txt-decor {
    text-decoration: none;
    border: none !important;
  }

  .campus-link-txt-border {
    border: none !important;
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $cccalendartoday;
    margin-top: 400px;
    padding: 10px 0;
    display: none; // this is required if u want to put visibilty only on scroll
  }

  .footer-visible {
    display: block;
  }
}

.campus-unauthorized-centered {
  position: fixed;
  top: 50%;
  left: 35%;
}
