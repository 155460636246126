@import "../../../../styles/scss/index.scss";

.news-bg {
  content: "";
  margin-top: 100px;
}
.public-news-header-text {
  color: #421d92 !important;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}

.cards-news-public {
  padding: 7px 10px;
  border: none;
  border-bottom: 1px solid #f0eeee;
  border-radius: 5px;
  background-color: $white;
  color: $onyx;
  text-align: center;
  margin-bottom: 10px;
}

.public-newsletter-img {
  max-width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.public-newsletter {
  /* Ensure that the container does not overflow horizontally */
  overflow: hidden;
  max-width: 100%;
}

.public-newsletter img,
.public-newsletter video,
.public-newsletter iframe {
  /* Ensure media elements like images, videos, and iframes fit within the container */
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the images/videos horizontally if needed */
}

.public-newsletter iframe {
  width: 100%; /* Ensure iframes take the full width of the container */
  height: auto; /* Adjust the height according to aspect ratio if needed */
}

.public-newsletter * {
  box-sizing: border-box; /* Ensure all child elements respect the padding/border */
}
