@import "../../../styles/scss/index.scss";

.navbar {
  background-color: $white;
  display: flex;
  //   justify-content: center;
  //   align-items: center;
  font-size: 1.2rem;
  position: fixed !important;
  top: 0;
  width: 100%;
  // width: calc(100% - 300px);
  z-index: 1030;
  box-shadow: 1px 1px 2px $alto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.nav-logo {
  color: $dimgray;
  align-items: center;
  // margin: 20px !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  margin: 0 15px !important;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: left;
  margin-right: 5px;
}

.nav-menu.active {
  background: $white !important;
  color: $onyx !important;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 99999;
  padding-right: 30px !important;
}

.nav-item {
  line-height: 40px !important;
  margin-left: 1px !important;
}

.nav-links {
  font-size: 16px;
  font-style: initial;
  color: $alto;
  text-decoration: none;
  height: 100%;
  border-bottom: 1px solid transparent;
}

.nav-icon-link {
  color: $darkred !important;
  margin-right: 5px;
  font-size: 0.81em;
  margin-bottom: 1px !important;
}

.nav-text-header {
  color: $dimgray;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    color: $darkred !important;
  }
}

.nav-text-header-active {
  color: $darkred;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: $dimgray !important;
  }
}

.nav-icon {
  display: none;
}

.nav-item-login {
  border: none;
  padding: 3px 0 !important;
}
.media-networn-icon {
  width: 20px;
  height: 20px;
}
.nav-icon-linkedin {
  color: "#0077B5" !important;
  background-color: "#0077B5" !important;
}
.hide-mobile-icons{
  display: block;
}
.hide-desktop-icons{
  display: none;
}
@media screen and (max-width: 480px) {
  .navbar {
    padding: 15px 0 !important;
  }
  .hide-mobile-icons{
    display: none;
  }
  .hide-desktop-icons{
    display: block;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: center;
    position: absolute;
    width: 100%;
    top: 60px;
    left: -105%;
    opacity: 1;
    transition: all 0.5s ease;
    border: none; // 1px solid $alto;
    // box-shadow: 2px 5px 10px $alto;
  }

  .nav-menu.active {
    background: $darkred;
    left: 0;
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    padding: 10px 0;
    width: 100%;
    display: table;
  }

  .nav-item {
    line-height: 20px !important;
    margin-left: 1px !important;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-30%, 30%);
    font-size: 1.8rem;
    cursor: pointer;
    color: $darkred;
  }

  .nav-icon:focus {
    color: $dimgray;
  }

  .nav-close-btn {
    font-size: 32px !important;
    margin-right: 5px;
  }

  .nav-open-btn {
    color: $dimgray;
  }

  li {
    // border-bottom: 1px solid $alto;
    padding: 10px 0;
    width: 100% !important;
  }

  .nav-text-header-mob {
    color: $onyx;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      color: $darkred !important;
    }
  }
  .nav-text-header {
    color: $white;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: $white !important;
    }
  }

  .nav-text-header-active {
    color: $white;
    font-size: 16px;
    font-weight: 600;
  }

  .nav-icon-link {
    color: $white !important;
  }

  .nav-icon-link-login {
    color: $white !important;
  }
  .nav-icon-link-register {
    color: $onyx !important;
  }
  .nav-item-login {
    border: none;
    padding: 10px 0 !important;
    display: flex;
    justify-content: center;
  }
}

.campus-ats-button {
  background-color: $onyx !important;
  border: none;
  border-radius: 6px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $white !important;
  padding: 8px 15px !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $white !important;
    background-color: $darkred !important;
  }

  &:hover {
    background-color: #015193 !important;
    color: $white !important;
  }
}
.campus-ats-button-lite {
  background-color: transparent !important;
  border: 1px solid #0e3e42 !important;
  border-radius: 20px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $onyx !important;
  padding: 5px 17px 8px 14px !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    color: $onyx !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent !important;
    color: $onyx !important;
  }
}
.nav-text-logo {
  color: #013763;
  font-size: 13px;
  font-weight: 600;
}