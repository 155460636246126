@import "../../../styles/scss/index.scss";

.header-market-one{
    margin-top: 90px !important;
}
.text-intro-header-market{
    font-size: 25px;
    font-weight: 600;
    color: $dimgray;
}
.card-market{
    border: 1px dotted $alto;
    border-radius: 5px;
    box-shadow: 0px 2px 1px rgb(0 55 183 / 8%);
}
.logo-market{
    height: 100px;
    width: 200px;
}
.logo-market-nav{
    height: 60px;
    width: 150px;
}
.logo-market-home-qall{
    height: 460px;
    width: auto;
}
.text-qual-market{
    font-size: 15px;
    font-weight: 600;
    color: #029b6b;
}
.text-qual-market-home{
    font-size: 35px;
    font-weight: 600;
    color: #468d85;
}
.text-qall-key-header-market{
    font-size: 22px;
    font-weight: 600;
    color: $dimgray;
}

.text-qual-market-grey{
    font-size: 17px;
    font-weight: 500;
    color: #5e6763;
}
.logo-market-key{
    height: 50px;
    width: 50px;
}
.bg-contact-qall{
    background-color: #029b6b;
    margin: 20px 0;
    padding-bottom: 20px;
}