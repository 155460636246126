@import "../../../../styles/scss/index.scss";

.cmps-candidate-row-jobs-tab-mobile {
  display: none;
}
.cmps-candidate-row-jobs-tab {
  display: block;
}
.text-address {
  color: $suvagrey !important;
  // letter-spacing: 1px;
  font-size: 15px;
  font-weight: 400;
}
.image-icon {
  height: 35px;
  width: 35px;
}
.bg-o-stream{
background-color: $cccalendartoday;
padding: 4px 5px;
}
.cmps-candidate-column-jobs-tab-left {
  float: left;
}
.cmps-candidate-column-jobs-tab-right {
  float: right;
}
.cmps-candidate-jobs-nav-tabs {
  color: $onyx !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 25px;
}

.cmps-candidate-jobs-nav-tabs.active {
  color: $spacecadet !important;
  letter-spacing: 1px;
}

.cmps-candidate-tab-jobs-icon {
  font-size: 17px;
  margin-right: 10px;
}
.cmps-candidate-contact-icon {
  color: $spacecadet !important;
  font-size: 12px;
}
.circle-user {
  width: 45px;
  height: 45px;
  padding: 5px 2px;
  text-align: center;
  vertical-align: center;
  border: 5px solid $spacecadet;
  border-radius: 50%;
}
.xcelian-filter-sub-header-text {
  color: $scorpion !important;
  font-size: 14px;
  font-weight: 600;
}

.xcelian-header-text {
  color: $white !important;
  font-size: 18px;
  font-weight: 600;
}
.xcelian-sub-header-text {
  color: $white !important;
  font-size: 15px;
  font-weight: 400;
}
.xcelian-filter-header-text {
  color: $onyx !important;
  font-size: 15px;
  font-weight: 700;
}

@media screen and (max-width: 960px) {
  .cmps-candidate-row-jobs-tab-mobile {
    display: block;
    margin-top: 10px;
  }

  .cmps-candidate-row-jobs-tab {
    display: none;
  }

  .cmps-candidate-tab-jobs-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .cmps-candidate-jobs-tab {
    border: none !important;
  }
}
