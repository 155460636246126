@import "../../../../styles/scss/index.scss";

.sadmin-dash-card {
  padding: 7px 10px;
  border: 1px solid #c0b6d7;
  border-radius: 10px;
  background-color: $white;
  color: $onyx;
  // box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
  text-align: center;
}
.sadmin-news-card {
  padding: 7px 10px;
  border: 1px solid #f0eeee;
  border-radius: 5px;
  background-color: $white;
  color: $onyx;
  text-align: center;
}
.dash-sadmin-fig-text {
  color: $darkred;
  font-size: 13px;
  font-weight: 700;
}
.button-sadmin-trans {
  background: transparent;
  border: none;
  color: #878096 !important;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  padding: 0;
  margin-bottom: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: #6937d5 !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}
.button-sadmin-filled {
  background: transparent;
  border: none;
  color: $darkred !important;
  font-size: 14px;
  font-weight: 600;
  font-style:normal;
  padding: 0;
  margin-bottom: 2px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: #f39154 !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
}

.newsletter-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;

  .newsletter-title {
    color: #333;
    font-size: 17px;
    text-align: center;
    font-weight: bold;
  }

  .newsletter-subtitle {
    font-size: 14px;
    color: #555;
    text-align: center;
  }

  .newsletter-example {
    font-size: 14px;
    color: #007bff;
    text-align: start;
    font-weight: 500;
  }

  .newsletter-section-title {
    color: #333;
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
  }

  .newsletter-text {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
    text-align: justify;
  }

  .newsletter-list {
    list-style: disc;
    padding-left: 14px;
    text-align: justify;

    li {
      margin-bottom: 10px;
    }
  }

  .newsletter-cta {
    font-size: 12px;
    color: #007bff;
    font-weight: bold;
    margin-top: 10px;
  }
}

.button-sadmin-newsletter-header {
  background: transparent;
  border: none;
  color: #421d92 !important;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  padding: 0;
  margin-bottom: 7px;
  text-align: justify !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: #878096 !important;
  }
  &:disabled {
    background-color: transparent;
    color: $suvagrey !important;
  }
  &:disabled {
    background-color: transparent;
    color: #6937d5 !important;
  }
}

.dash-sadmin-news-header-text {
  color: #421d92 !important;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}
.dash-sadmin-news-description {
  color: $suvagreydrk;
  font-size: 14px;
  font-weight: 400;
}
.dash-sadmin-news-border {
  border: 3px solid #fff8f8;
  border-radius: 10px;
  padding: 5px 10px;
}
.new-layout-newsletter {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify !important;
}

.dash-sadmin-news-description {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.newsletter-img {
  width: 100%;
  height: auto;
}

.detail-newsletter {
  /* Ensure that the container does not overflow horizontally */
  overflow: hidden;
  max-width: 100%;
}

.detail-newsletter img,
.detail-newsletter video,
.detail-newsletter iframe {
  /* Ensure media elements like images, videos, and iframes fit within the container */
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the images/videos horizontally if needed */
}

.detail-newsletter iframe {
  width: 100%; /* Ensure iframes take the full width of the container */
  height: auto; /* Adjust the height according to aspect ratio if needed */
}

.detail-newsletter * {
  box-sizing: border-box; /* Ensure all child elements respect the padding/border */
}

@media screen and (max-width: 480px) {
  .padding-mob-top-subs {
    padding-top: 35px !important;
  }
}