@import '../../../../styles/scss/index.scss';

.campus-register-bg {
    content: "";
    background-image: url("../../../../../public/images/common/bg-exclusive.png");
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // min-height: 110vh;
}

.register-campus-image {
    border-radius: 50%;
    // padding: 20px;
}
.bg-gender-register{
    background-color: $seashell;
    padding: 6px 2px;
    border-radius: 10px;
}