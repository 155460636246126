@import "../../../../../../styles/scss/index.scss";

#template2-card {
  border: none !important;
  padding: 20pt 25pt 72pt 25pt;
  // padding-bottom: 65px !important;
}
.template2-img-icon {
  height: 12px;
  width: 12px;
  margin-bottom: 2px;
}
.template2-card-design {
  border: 1px solid #fafafa;
  border-radius: 7px;
  // padding-bottom: 65px !important;
  // padding: 10px 15px;
}
.template2-img-icon-main {
  height: 15px;
  width: 15px;
  margin-bottom: 5px;
}
.template2-card-design {
  border: none !important;
}
.template2-header{
  font-size: 16px;
  font-weight: 700;
  color: rgb(3, 148, 148);
}
.template2-subheader{
  font-size: 15px;
  font-weight: 600;
  color: rgb(13, 129, 129);
}
.template2-subheader-italic{
  font-size: 15px;
  font-weight: 500;
  color: rgb(97, 105, 105);
  font-style: italic;
}
.template2-hr {
  border: 1px solid rgb(184, 206, 206);
  padding: 0;
  margin: 0;
}
.template2-text {
  font-size: 15px;
  font-weight: 400;
  color: #4c4343;
}