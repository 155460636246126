@import "../../../../styles/scss/index.scss";

.pro-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}
.academy-info-text-lite{
  font-size: 12px;
  color: $white;
}
.academy-info-text-bold{
  font-size: 13px;
  color: $white;
  font-weight: 600;
}
.pro-sidebar-header {
  background: $darkred;
  border: none !important;
}

.pro-sidebar-content {
  background: $darkred;
}

.pro-sidebar-footer {
  background: $darkred;
  border-top: 1px solid $seashell !important;
}

.cx-avatar-user-small {
  margin: auto;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  transform: scale(.80, .8)
}

.cx-avatar-user-large {
  height: 50px;
  width: 50px;
}

.cx-text-avatar-name {
  color: $white;
  font-size: 17px;
  font-weight: 700;

}

.cx-text-avatar-designation {
  color: $white;
  font-size: 12px;
  font-weight: 500;
}

.closemenu-cx {
  color: white !important;
  background: $greenvogue;
  position: relative;
  right: 0px;
  z-index: 9999;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: -12px;
  background: $darkred;
  cursor: pointer;
  float: right;
}

.cx-side-border {
  border-top: 1px solid $white;
  margin-top: 21px;
}

.overflow {
  min-height: 90vh;
  bottom: 0;
}

.menu-item-cx {
  margin: 5px 1px;
}

.cx-link-txt {
  color: $white;
  font-weight: 600;
  font-size: 14px;
  margin-left: 1px;
}

.menu-item-cx:hover {
  background: #757377;
  border-radius: 2px !important;
}

.menu-icon-cx {
  color: $dimgray;
  font-size: 14px;
}

.pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  border-radius: 2px !important;
  background: $white !important;
  border-radius: 8px !important;
}

.pro-sidebar-inner .pro-sidebar-layout .active {
  border-radius: 2px;
  background: #757377;
}
.menu-img-cx{
  height: 25px !important;
  width: 25px !important;
  padding: 1px;
}

// Campus Admin Start
.admin-pro-sidebar-header {
  background: $spacecadet;
  border: none !important;
}

.admin-pro-sidebar-content {
  background: $spacecadet;
}

.admin-pro-sidebar-footer {
  background: $spacecadet;
  border-top: 1px solid $seashell !important;
}

// Campus Admin End

// Super Admin Start
.sadmin-pro-sidebar-header {
  background: $britvil;
  border: none !important;
}

.sadmin-pro-sidebar-content {
  background: $britvil;
}

.sadmin-pro-sidebar-footer {
  background: $britvil;
  border-top: 1px solid $seashell !important;
}

// Super Admin End