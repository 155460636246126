@import '../../../../styles/scss/index.scss';

.bg-ats-login {
    background-image: linear-gradient(
      95deg,
      rgb(251, 240, 222) 10%,
      #f6fafd,
      #fafdff,
      rgb(255, 255, 255) 65%
    );
    min-height: 110vh;
    //   border-top-left-radius: 100px;
    //   border-bottom-right-radius: 150px;
    //   border-bottom-left-radius: 50px;
    // padding: 50px;
  }
.login-align-vertical {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 2rem;
}
.ats-margin-login{
    margin:10px 6rem;
}
.ats-icon{
    height: 20px;
    width: 20px;
}
.campus-ats-button-full-width {
    background-color: $onyx !important;
    border: 1px solid $onyx !important;
    border-radius: 40px;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $white !important;
    padding: 10px 0 !important;
    width: 100%;
    cursor: pointer;
  
    &:focus {
      outline: 0;
      box-shadow: none !important;
      color: $white !important;
      background-color: $darkred !important;
    }
  
    &:hover {
      background-color: $darkred !important;
      color: $white !important;
    }
  }
  
@media screen and (max-width: 480px) {
    .vertical-align{
        border: none;
        padding-top: 50px;
    }
    .login-card {
        width: 100% !important;
    }
    .ats-margin-login{
        margin:4rem 0;
    }
}
