@import "../../../../../styles/scss/index.scss";

.resume-margin-top {
    margin-top: 20px;
  }
  .do-know-cert{
    height: 70px;
    width: auto;
  }
  .cx-resume-img-card {
    border: none;
    border-radius: 50%;
    padding: 10px;
    background-color: $seashell;
    height: 50px;
    width: 50px;
  }
  .cx-resume-header-text {
    font-size: 14px;
    font-weight: 600;
    color: $scorpion;
    font-style: italic;
  }
.campus-resume-button {
    color: $suvagrey !important;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 4px 1px !important;
    width: 100%;
    padding: 10px 1px;
    background: #f4f1f1 !important;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
  }
  .cert-image-list-icon{
    background-color: transparent;
    padding: 1px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px dashed #fbceb6;
  }
  .input-header-text{
    color: $onyx;
    font-weight: 600;
  }
  .cert-image-list{
    background-color: transparent;
    padding: 1px;
    max-height: 200px;
    width: auto;
  }
  .cert-all-image-list{
    max-height: 150px;
  }
  .campus-resume-button.active {
    color: $onyx !important;
    // background: $white !important;
    letter-spacing: 1px;
    border: 1px solid $cccalendartoday !important;
    border-radius: 15px;
    box-shadow: 0 3px 5px rgba(10, 10, 10, 0.6);
    background-image: linear-gradient(150deg,
    #fbf5ea 50%,
    #fbceb6 90%) !important;
  }

  .campus-tab-resume-icon-img {
    height: 30px;
    width: 30px;
}
.video-quote-text{
  color: $darkred;
  font-style: italic;
}
.video-icon-text{
  font-size: 12px;
  color: $darkred
}
.video-header-text{
  font-weight: 600;
  color: $suvagrey
}
.video-sub-header-text{
  font-size: 14px;
  font-weight: 500;
  color: $onyx
}
.video-card{
  border: 1px solid #6bfdbb;
  border-radius: 20px;
  padding: 5px 15px;
}

@media screen and (max-width: 480px) {
   
}